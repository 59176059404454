// API
import api from '~/services/api';

const Listar = async (endereco_uuid) => {

    try {
        const uendereco_uuid = `endereco=${endereco_uuid}`

        const query_params = `?${uendereco_uuid}`

        const { data: response_data } = await api.get(`/private/enderecos_logs${query_params}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const EnderecosLogsRequests = {
    listar: Listar,
};

export default EnderecosLogsRequests;