import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
import { getFirestore, collection, getDocs, doc, getDoc } from "firebase/firestore";

/**
 * Variáveis
 */
let firebase_app = false;

const Setup = () => {

    if (firebase_app == false) {

        /**
         * Configura o Firebase
         */
        firebase_app = initializeApp({
            apiKey: "AIzaSyAn8ODVZ6iFJmsUKLky9bWU6E_URs5guoI",
            authDomain: "fontanariohidrocaldas.firebaseapp.com",
            projectId: "fontanariohidrocaldas",
            storageBucket: "fontanariohidrocaldas.appspot.com",
            messagingSenderId: "798327522807",
            appId: "1:798327522807:web:beec00a3cadc0115cfdaf2",
            measurementId: "G-CKNGT77KBF"
        });

        /**
         * Configura o analytics
         */
        getAnalytics(firebase_app);

    }

}

const Firestore = () => {
    return getFirestore(firebase_app);
}

const SignInToken = async (token) => {
    try {

        const auth = getAuth();

        return await signInWithCustomToken(auth, token);

    } catch (error) {
        throw error;
    }
}

const SignOut = async () => {
    try {

        const auth = getAuth();

        return await signOut(auth);

    } catch (error) {
        throw error;
    }
}

// const QuantidadeEnderecosPendentes = async () => {

//     const db = Firestore();

//     const docSnap = await getDoc(doc(db, "enderecos", "pendentes_ativar"));

//     const { quantidade } = docSnap.data();

//     return quantidade || 0;

// }




const FirebaseService = {
    Setup,
    Firestore,
    SignInToken,
    SignOut
    // QuantidadeEnderecosPendentes
}

export default FirebaseService;