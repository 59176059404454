import React, { useMemo, useEffect, useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6 } from '~/components/Bootstrap/Col';
import InputGroup from '~/components/Bootstrap/InputGroup';
import Label from '~/components/Bootstrap/Label';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';
import ButtonDanger from '~/components/Buttons/Normal/Danger';

// UTILS
import numeral from '~/utils/numeral';
import Utils from '~/utils';
import NotificacaoUtil from '~/utils/notificacao';

// SERVICES
import APIRequests from "~/services/requests_api";

const ModalCadastroEdicao = forwardRef(
    ({
        onConfirmCadEdit = () => { }
    }, ref) => {

        const [isVisible, setVisible] = useState(false);

        const [UUID, setUUID] = useState('');
        const [Litros, setLitros] = useState('');
        const [Situacao, setSituacao] = useState('ativo');

        const onRequestConfirm = async () => {

            try {
                const NLitros = parseFloat(Litros);

                if (NLitros == 0) {
                    throw new Error('Informe a quantidade maior do que 0.0000');
                }

                // formata a descricao
                const descricao = `${NLitros} Litros`.replace(`.`, ',');

                const objeto_enviar = {
                    descricao: descricao,
                    litros: NLitros,
                    situacao: Situacao,
                }

                let retorno_api = {};
                if (UUID) {
                    retorno_api = await APIRequests.produtos.atualizar(UUID, objeto_enviar);
                } else {
                    retorno_api = await APIRequests.produtos.adicionar(objeto_enviar);
                }

                if (retorno_api?.id) {

                    NotificacaoUtil.success({
                        msg: UUID ? `Produto atualizado com sucesso` : 'Produto cadastrado com sucesso',
                    });

                    // fecha o modal
                    onRequestClose();

                    // dispara a função externa
                    onConfirmCadEdit();

                } else {
                    NotificacaoUtil.error({
                        msg: UUID ? `Erro ao atualizar o produto` : `Erro ao cadastrar o produto`,
                        timeout: 3500,
                    });
                }

            } catch (error) {
                NotificacaoUtil.error({
                    msg: error.message,
                    timeout: 3500,
                });
            }

        }

        const onRequestRemove = async () => {

            try {

                const retorno_api = await APIRequests.produtos.apagar(UUID);

                if (retorno_api?.id) {

                    NotificacaoUtil.success({
                        msg: `Produto removido com sucesso`,
                    });

                    // fecha o modal
                    onRequestClose();

                    // dispara a função externa
                    onConfirmCadEdit();

                } else {
                    NotificacaoUtil.error({
                        msg: `Erro ao remover o produto`,
                        timeout: 3500,
                    });
                }

            } catch (error) {
                NotificacaoUtil.error({
                    msg: error.message,
                    timeout: 3500,
                });
            }

        }

        const onRequestOpen = async (uuid = false) => {

            let visible = true;

            if (uuid) {

                const dados_produto = await APIRequests.produtos.obter(uuid);

                if (dados_produto.uuid) {

                    setUUID(dados_produto.uuid);
                    setSituacao(dados_produto.situacao);
                    setLitros(dados_produto.litros);

                } else {
                    visible = false;
                    NotificacaoUtil.error({
                        msg: 'Falha ao carregar os dados do produto.'
                    });
                }

            }

            if (visible) {
                setVisible(visible);
            }

        }

        const onRequestClose = () => {
            setVisible(false);

            // reseta o estado
            setUUID('');
            setLitros('');
            setSituacao('ativo');
        }

        const onKeyDown = (e) => {

            // pega o código pressionado
            const code = e.which !== false ? e.which : e.keyCode;

            // stop events
            if (code == 13 || code == 27) {
                e.preventDefault();
            }

            if (code == 13) {
                onRequestConfirm();
            }

            if (code == 27) {
                onRequestClose();
            }

        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <Modal
                id="modal_cadastro_edicao_produto"
                isVisible={isVisible}
                setVisible={onRequestClose}
                closeButtonVisible={false}
                closeOnClickOutside={false}
                width={420}
                height={320}
            >
                <Main>
                    <ModalHeader>
                        {UUID ? 'Edição' : 'Cadastro'} de Produto
                    </ModalHeader>

                    <Content>

                        <Row>
                            <ColSm12>
                                <Label>Litros</Label>
                                <InputNormal
                                    value={Litros}
                                    onKeyDown={onKeyDown}
                                    onChange={e => {
                                        const { value } = e.target;

                                        const litros = Utils.MascaraInputDecimal4Dig(value);

                                        if (litros !== -1) {
                                            setLitros(litros);
                                        }
                                    }}
                                />
                            </ColSm12>
                        </Row>

                        <Row>
                            <ColSm12>
                                <Label>Situação</Label>
                                <SelectNormal value={Situacao} onChange={e => setSituacao(e.target.value)} onKeyDown={onKeyDown}>
                                    <option value={'ativo'}>Ativo</option>
                                    <option value={'inativo'}>Inativo</option>
                                </SelectNormal>
                            </ColSm12>
                        </Row>
                        <Footer>

                            <ButtonSecondary onClick={onRequestClose}>
                                Fechar
                            </ButtonSecondary>

                            <div>

                                {UUID != '' && (
                                    <ButtonDanger onClick={onRequestRemove}>
                                        Remover
                                    </ButtonDanger>
                                )}

                                <ButtonPrimary onClick={onRequestConfirm}>
                                    OK
                                </ButtonPrimary>

                            </div>

                        </Footer>

                    </Content>
                </Main>
            </Modal>
        )

    }
);

export default ModalCadastroEdicao;