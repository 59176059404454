const AS_LAST_USER_LOGGED = '@fontanario/last_user_logged';
const AS_USER_TOKEN = '@fontanario/user_token';
const AS_USER_DATA = '@fontanario/user_data';
const AS_SESSION_VALIDITY = '@fontanario/session_validity';

export default {
    AS_LAST_USER_LOGGED,
    AS_USER_TOKEN,
    AS_USER_DATA,
    AS_SESSION_VALIDITY
}