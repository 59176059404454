import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";

// SERVICES
import APIRequests from "~/services/requests_api";
import AuthService from "~/services/auth";
// COMPONENTES GERAIS
import InputSmall from "~/components/Inputs/Normal/Small";
import SearchInput from "~/components/SearchInput";

import Row from "~/components/Bootstrap/Row";

// COMPONENTES DE BASEPAGE
import {
    BasePageMain,
    BasePageContainer,
    BasePageBox,
    BasePageTitleContainer,
    BasePageTitle,
    BasePageButtonsContainer,
    BasePageTopLineSeparator,
} from "~/components/BasePage";

// BOOTSTRAP
import { ColSm2, ColSm3, ColSm4, ColSm5, ColSm7, ColSm9 } from "~/components/Bootstrap/Col";

// TABLE
import Table from "~/components/TableR";

// COMPONENTES INTERNOS
import ModalNovoLancamentoCaixa from "./components/ModalNovoLancamento";
import ModalDetalhesLancamentoCaixa from "./components/ModalDetalhesLancamento";
import ModalEstornarLancamentoCaixa from "./components/ModalEstornarLancamento";
import ModalNovoCreditoUsuario from "./components/ModalNovoCreditoUsuario";
import ModalFechamentoCaixa from "./components/ModalFechamentoCaixa";

// COMPONENTES GERAIS
import DateCalendarModalV2 from "~/components/DateCalendarModalV2";
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from "~/components/Buttons/Small/Primary";
import ButtonDanger from "~/components/Buttons/Normal/Danger";
import ButtonOutlineSmallPrimary from "~/components/ButtonsOutline/Small/Primary";
import ButtonOutlineSmallDanger from "~/components/ButtonsOutline/Small/Danger";

// UTILS
import Utils from "~/utils";
import moment from "~/utils/moment";
import InputGroup, { InputGroupAddon, InputGroupButton } from "~/components/Bootstrap/InputGroup";

export default function PageCaixa() {
    const RefModalData = useRef(null);
    const RefModalAddMov = useRef(null);
    const RefModalDetailsMov = useRef(null);
    const RefModalChargeBack = useRef(null);
    const RefModalAddCred = useRef(null);
    const RefModalCloseTill = useRef(null);

    /**
     * Data atual com o moment.
     */
    const TodayDateMoment = moment();

    /**
     * Dados de navegação
     */
    const [NavSorted, setNavSorted] = useState([]);
    const [NavPageCount, setNavPageCount] = useState(0);
    const [NavCurrentPage, setNavCurrentPage] = useState(1);
    const [NavLimitPerPage, setNavLimitPerPage] = useState(10);
    const [NavTotalRows, setNavTotalRows] = useState(0);

    /**
     * Lista de registros
     */
    const [SaldoCaixa, setSaldoCaixa] = useState(0);
    const [Registros, setRegistros] = useState([]);

    const [Pesquisa, setPesquisa] = useState("");

    const [Data, setData] = useState(TodayDateMoment.format("DD/MM/YYYY"));
    const [DataUS, setDataUS] = useState(TodayDateMoment.format("YYYY-MM-DD"));

    const [AtualizandoSaldo, setAtualizandoSaldo] = useState(false);

    /**
     * Carrega os Registros da
     */
    const goLoadRegistros = useCallback(async () => {
        const registros = await APIRequests.caixa.listar({
            query: Pesquisa,
            date: DataUS,
            page: NavCurrentPage,
            limit: NavLimitPerPage,
            sorted: NavSorted,
        });

        if (registros.results) {
            setSaldoCaixa(registros.saldo);
            setRegistros(registros.results);
            setNavPageCount(registros.metadata.page.total);
            setNavTotalRows(registros.metadata.total);
        }

        setTimeout(() => {
            setAtualizandoSaldo(false);
        }, 300);
    }, [Pesquisa, DataUS, NavLimitPerPage, NavCurrentPage, NavSorted]);

    const onRequestAddMov = async () => {
        await RefModalAddMov?.current?.open();
    };

    const onRequestAddCreditToUser = async () => {
        await RefModalAddCred?.current?.open();
    };

    const onRequestCloseTill = async () => {
        await RefModalCloseTill?.current?.open();
    };

    const onRequestSellChips = async () => {
        await RefModalAddMov?.current?.open(true);
    };

    const onRequestDetailsMov = (data) => {
        RefModalDetailsMov?.current?.open(data);
    };

    const onRequestChargeBack = (data) => {
        RefModalChargeBack?.current?.open(data);
    };

    /**
     * Abre o modal de data.
     */
    const onRequestChangeDate = async () => {
        const date = await RefModalData.current.RequestDate({ current_date: DataUS });

        if (date.status == "ok") {
            const nw_date = moment(date.date).format("DD/MM/YYYY");

            setNavCurrentPage(1);
            setDataUS(date.date);
            setData(nw_date);
        }
    };

    /**
     * Quando navegar na tabela
     */
    const onTableNavChange = useCallback((data) => {
        setNavSorted(data.sortBy);
        setNavLimitPerPage(data.limit);
        setNavCurrentPage(data.page + 1);
    }, []);

    /**
     * Dispara requisições para backend.
     */
    useEffect(() => {
        let timeout = setTimeout(() => {
            goLoadRegistros();
        }, 100);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        };
    }, [DataUS, Pesquisa, NavLimitPerPage, NavCurrentPage, NavSorted]);

    /**
     * Colunas da tabela
     */
    const TableColumns = useMemo(
        () => [
            {
                accessor: "uuid",
                width: 25,
                disableSortBy: true,
                Header: () => <center>#</center>,
                Cell: ({ value, row }) => {
                    const { original } = row;
                    return (
                        <center>
                            <ButtonPrimary onClick={() => onRequestDetailsMov(original)} title="Ver lançamento">
                                <i className="fas fa-eye" />
                            </ButtonPrimary>

                            {AuthService.checkPermission("caixa", "estornar_movimento") && (
                                <ButtonDanger onClick={() => onRequestChargeBack(original)} title="Estornar lançamento">
                                    <i className="fas fa-hand-holding-dollar" />
                                </ButtonDanger>
                            )}
                        </center>
                    );
                },
            },
            {
                Header: "Data movimento",
                accessor: "data_movimento",
                width: 70,
                Cell: ({ value, row }) => {
                    return Utils.FormataDataHora(value);
                },
            },
            {
                Header: "Histórico",
                accessor: "historico",
            },
            {
                Header: "Entrada",
                accessor: "entrada",
                width: 50,
                Cell: ({ value, row }) => <div style={{ color: value > 0 ? "#41924c" : "" }}>{Utils.FormataTotal(value)}</div>,
            },
            {
                Header: "Saída",
                accessor: "saida",
                width: 50,
                Cell: ({ value, row }) => <div style={{ color: value > 0 ? "#b14545" : "" }}>{Utils.FormataTotal(value)}</div>,
            },
            {
                Header: "Espécie",
                accessor: "especie",
                width: 50,
            },
            {
                Header: "Operador",
                accessor: "administrador.nome",
                width: 100,
            },
        ],
        [],
    );

    /**
     * SortBy default
     */
    const TableInitialSortBy = [
        {
            id: "data_movimento",
            desc: true,
        },
    ];

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">
                <BasePageTitleContainer>
                    <BasePageTitle>Caixa</BasePageTitle>
                </BasePageTitleContainer>

                <BasePageTopLineSeparator />

                <BasePageButtonsContainer>
                    {AuthService.checkPermission("caixa", "adicionar_movimento") && (
                        <>
                            <ButtonPrimary onClick={onRequestAddMov}>Adicionar movimento</ButtonPrimary>
                            <ButtonPrimary onClick={onRequestSellChips}>Venda fichas</ButtonPrimary>
                        </>
                    )}

                    {AuthService.checkPermission("caixa", "adicionar_credito") && (
                        <ButtonOutlineSmallPrimary onClick={onRequestAddCreditToUser}>Inserir crédito para usuário</ButtonOutlineSmallPrimary>
                    )}

                    {AuthService.checkPermission("caixa", "fechar_caixa") && (
                        <ButtonOutlineSmallDanger onClick={onRequestCloseTill}>Fechamento de caixa</ButtonOutlineSmallDanger>
                    )}
                </BasePageButtonsContainer>

                <BasePageBox id="box">
                    <Row>
                        <ColSm2>
                            <InputSmall value={Data} readOnly onClick={onRequestChangeDate} />
                        </ColSm2>
                        <ColSm3>
                            <InputGroup small>
                                <InputGroupAddon>SALDO</InputGroupAddon>
                                <InputSmall
                                    style={{ color: SaldoCaixa > 0 ? "#41924c" : "#b14545" }}
                                    value={Utils.FormataTotal(SaldoCaixa)}
                                    disabled
                                />
                                <InputGroupButton
                                    onClick={() => {
                                        setAtualizandoSaldo(true);
                                        goLoadRegistros();
                                    }}>
                                    <i className={`fas fa-sync-alt ${AtualizandoSaldo ? "fa-spin" : ""}`} />
                                </InputGroupButton>
                            </InputGroup>
                        </ColSm3>
                        <ColSm4 />
                        <ColSm3>
                            <SearchInput
                                inputType="small"
                                onSearch={(text) => {
                                    setNavCurrentPage(1);
                                    setPesquisa(text);
                                }}
                            />
                        </ColSm3>
                    </Row>

                    <div style={{ marginTop: 10 }}>
                        <Table
                            nostriped
                            initialSortBy={TableInitialSortBy}
                            pageIndex={NavCurrentPage - 1}
                            pageCount={NavPageCount}
                            totalRows={NavTotalRows}
                            data={Registros}
                            columns={TableColumns}
                            onChangeNav={onTableNavChange}
                        />
                    </div>
                </BasePageBox>
            </BasePageContainer>

            <DateCalendarModalV2 ref={RefModalData} />

            <ModalNovoLancamentoCaixa ref={RefModalAddMov} onConfirm={goLoadRegistros} />

            <ModalDetalhesLancamentoCaixa ref={RefModalDetailsMov} />

            <ModalNovoCreditoUsuario ref={RefModalAddCred} onConfirm={goLoadRegistros} />

            <ModalFechamentoCaixa ref={RefModalCloseTill} onConfirm={goLoadRegistros} />

            <ModalEstornarLancamentoCaixa ref={RefModalChargeBack} onConfirm={goLoadRegistros} />
        </BasePageMain>
    );
}
