import { Buffer } from 'buffer';

// API
import api from '~/services/api';

const Listar = async ({
    filter_cpf_only = 0,
    query = '',
    limit = 100,
    page = 1,

    sorted = []
}) => {

    try {

        const query_params_arr = [];

        query_params_arr.push(`query=${query}`);
        query_params_arr.push(`limit=${limit}`);
        query_params_arr.push(`page=${page}`);
        query_params_arr.push(`filter_cpf_only=${filter_cpf_only}`);

        if (sorted?.length > 0) {
            const sorted_b64 = Buffer.from(JSON.stringify(sorted)).toString('base64');
            query_params_arr.push(`sorted=${sorted_b64}`);
        }

        const query_params = query_params_arr.length > 0 ? `?${query_params_arr.join('&')}` : '';

        const { data: response_data } = await api.get(`/private/usuarios${query_params}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Obter = async (uuid = '') => {

    try {

        const { data: response_data } = await api.get(`/private/usuarios/${uuid}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Adicionar = async (dados = {}) => {

    try {

        const { data: response_data } = await api.post(`/private/usuarios`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Atualizar = async (uuid = '', dados = {}) => {

    try {

        const { data: response_data } = await api.put(`/private/usuarios/${uuid}`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}


const UsuariosRequests = {
    listar: Listar,
    obter: Obter,
    // adicionar: Adicionar,
    atualizar: Atualizar,
};

export default UsuariosRequests;