import Inputmask from "inputmask";
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.br';

export const GeraDataMaskKey = () => {
    var k = "",
        p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
        t = 10;

    for (var i = 0; i < t; i++) {
        k += p.charAt(Math.floor(Math.random() * p.length));
    }
    return k;
}

const ConfigureMaskInput = (mask = '', selector = '') => {

    const selector_string = `[data-mask-key='${selector}']`;

    switch (mask) {
        case 'phone':
            Inputmask({ "mask": ["(99) 9999-9999", "(99) 99999-9999"], greedy: true }).mask(
                document.querySelectorAll(selector_string)
            );
            break;

        case 'cpf_cnpj':
            Inputmask({ "mask": ["999.999.999-99", "99.999.999/9999-99"], greedy: true }).mask(
                document.querySelectorAll(selector_string)
            );
            break;

        case 'date':
            Inputmask({ alias: "datetime", inputFormat: "dd/mm/yyyy" }).mask(
                document.querySelectorAll(selector_string)
            );
            break;

        case 'time':
            Inputmask({ alias: "datetime", inputFormat: "HH:MM" }).mask(
                document.querySelectorAll(selector_string)
            );
            break;


        default:
            break;
    }

}

export default ConfigureMaskInput;