import ASSelect from 'react-select/async';

const ReactAsyncSelect = ({
    autoFocus = false,
    isClearable = true,
    cacheOptions = false,
    placeholder = "Digite para pesquisar...",

    defaultValue = "",
    value = null,

    onChange = () => { },
    loadOptions = () => { },
}) => {

    return (
        <ASSelect
            defaultOptions

            autoFocus={autoFocus}
            cacheOptions={cacheOptions}
            loadOptions={loadOptions}
            isClearable={isClearable}

            {...(value ? {
                "value": value,
            } : {})}

            defaultValue={defaultValue}

            noOptionsMessage={() => "Sem resultados"}
            loadingMessage={() => "Carregando ..."}
            placeholder={placeholder}

            onChange={onChange}

            styles={{
                control: (styles, state) => (
                    {
                        ...styles,
                        borderRadius: 2,
                        boxShadow: 'none',
                        borderColor: (!state.isFocused) ? '#ccc' : '#6c8fb1',

                        ':hover': {
                            borderColor: '#6c8fb1',
                        },

                        ':active': {
                            borderColor: '#6c8fb1',
                        },
                    }
                )
            }}
        />
    )
}

export default ReactAsyncSelect;