import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// SERVICES
import AuthService from "~/services/auth";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle } from "~/components/BasePage";

// STYLES
import { ButtonRelatorio } from "./components/styles";

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from '~/components/Buttons/Small/Primary';


// COMPONENTES RELATÓRIOS
import FechamentoCaixa from './FechamentoCaixa';
import RankingVendasPorCliente from './RankingVendasPorCliente';
import RelatorioDeVendas from "./RelatorioDeVendas";
import RelatorioTrasacoesOnline from './RelatorioTransacoesOnline/';
import RelatorioVendasContabilidade from "./RelatorioVendasContabilidade";
import RelatorioEnderecosAprovados from "./RelatorioEnderecosAprovados";



export default function PageRelatorios() {


    const [RelatorioSelecionado, setRelatorioSelecionado] = useState('FECHAMENTO_DE_CAIXA');


    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Relatórios
                    </BasePageTitle>
                </BasePageTitleContainer>

                <BasePageBox id="box" >

                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                        {AuthService.checkPermission('relatorios', 'fechamento_de_caixa') && (
                            <ButtonRelatorio backgroundColorFocus={RelatorioSelecionado == 'FECHAMENTO_DE_CAIXA' ? '#1b5376' : null} onClick={() => setRelatorioSelecionado('FECHAMENTO_DE_CAIXA')}>
                                Fechamento de caixa
                            </ButtonRelatorio>
                        )}

                        {AuthService.checkPermission('relatorios', 'relatorio_de_vendas') && (
                            <ButtonRelatorio backgroundColorFocus={RelatorioSelecionado == 'RELATORIO_DE_VENDAS' ? '#1b5376' : null} onClick={() => setRelatorioSelecionado('RELATORIO_DE_VENDAS')}>
                                Relatório de vendas
                            </ButtonRelatorio>
                        )}

                        {AuthService.checkPermission('relatorios', 'relatorio_vendas_contabilidade') && (
                            <ButtonRelatorio backgroundColorFocus={RelatorioSelecionado == 'RELATORIO_DE_VENDAS_CONTABILIDADE' ? '#1b5376' : null} onClick={() => setRelatorioSelecionado('RELATORIO_DE_VENDAS_CONTABILIDADE')}>
                                Relatório de vendas - contabilidade
                            </ButtonRelatorio>
                        )}

                        {AuthService.checkPermission('relatorios', 'ranking_vendas_por_cliente') && (
                            <ButtonRelatorio backgroundColorFocus={RelatorioSelecionado == 'RANKING_DE_VENDAS_POR_CLIENTE' ? '#1b5376' : null} onClick={() => setRelatorioSelecionado('RANKING_DE_VENDAS_POR_CLIENTE')}>
                                Ranking de vendas por cliente
                            </ButtonRelatorio>
                        )}

                        {AuthService.checkPermission('relatorios', 'relatorio_transacoes_online') && (
                            <ButtonRelatorio backgroundColorFocus={RelatorioSelecionado == 'RELATORIO_DE_TRANSACOES_ONLINE' ? '#1b5376' : null} onClick={() => setRelatorioSelecionado('RELATORIO_DE_TRANSACOES_ONLINE')}>
                                Relatório de transações online
                            </ButtonRelatorio>
                        )}

                        {AuthService.checkPermission('relatorios', 'relatorio_enderecos_aprovados') && (
                            <ButtonRelatorio backgroundColorFocus={RelatorioSelecionado == 'RELATORIO_ENDERECOS_APROVADOS' ? '#1b5376' : null} onClick={() => setRelatorioSelecionado('RELATORIO_ENDERECOS_APROVADOS')}>
                                Relatório de endereços aprovados
                            </ButtonRelatorio>
                        )}
                    </div>

                    <div>

                        {RelatorioSelecionado == 'FECHAMENTO_DE_CAIXA' && (
                            <FechamentoCaixa />
                        )}

                        {RelatorioSelecionado == 'RELATORIO_DE_VENDAS' && (
                            <RelatorioDeVendas />
                        )}

                        {RelatorioSelecionado == 'RELATORIO_DE_VENDAS_CONTABILIDADE' && (
                            <RelatorioVendasContabilidade />
                        )}

                        {RelatorioSelecionado == 'RANKING_DE_VENDAS_POR_CLIENTE' && (
                            <RankingVendasPorCliente />
                        )}

                        {RelatorioSelecionado == 'RELATORIO_DE_TRANSACOES_ONLINE' && (
                            <RelatorioTrasacoesOnline />
                        )}

                        {RelatorioSelecionado == 'RELATORIO_ENDERECOS_APROVADOS' && (
                            <RelatorioEnderecosAprovados />
                        )}

                    </div>

                </BasePageBox>






            </BasePageContainer>


        </BasePageMain >
    );
}
