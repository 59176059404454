const default_dt = { msg: '', timeout: 2500, show: true };

const success = (dt = default_dt) => {
    dt = {
        ...default_dt,
        ...dt
    };

    const noty = new Noty({
        type: 'success',
        timeout: dt.timeout,
        text: dt.msg,
    })

    if (dt.show) {
        noty.show();
    }

    return noty;
}

const error = (dt = { ...default_dt, timeout: 3500 }) => {
    dt = {
        ...default_dt,
        ...dt
    };

    const noty = new Noty({
        type: 'error',
        timeout: dt.timeout,
        text: dt.msg,
    })

    if (dt.show) {
        noty.show();
    }

    return noty;
}

const warning = (dt = { ...default_dt, timeout: 3500 }) => {
    dt = {
        ...default_dt,
        ...dt
    };

    const noty = new Noty({
        type: 'warning',
        timeout: dt.timeout,
        text: dt.msg,
    })

    if (dt.show) {
        noty.show();
    }

    return noty;
}

const NotificacaoUtil = {
    success: success,
    error: error,
    warning,
};

export default NotificacaoUtil;