import { Buffer } from 'buffer';

// API
import api from '~/services/api';

// SERVICES
import AuthService from '~/services/auth';

const Listar = async ({
    date = '',

    query = '',
    limit = 100,
    page = 1,

    sorted = []
}) => {

    try {

        const query_params_arr = [];

        query_params_arr.push(`query=${query}`);
        query_params_arr.push(`limit=${limit}`);
        query_params_arr.push(`page=${page}`);

        if (date) {
            query_params_arr.push(`start_date=${date} 00:00:00`);
            query_params_arr.push(`end_date=${date} 23:59:59`);
        }

        if (sorted?.length > 0) {
            const sorted_b64 = Buffer.from(JSON.stringify(sorted)).toString('base64');
            query_params_arr.push(`sorted=${sorted_b64}`);
        }

        const query_params = query_params_arr.length > 0 ? `?${query_params_arr.join('&')}` : '';

        const { data: response_data } = await api.get(`/private/livro_caixa${query_params}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Obter = async (uuid = '') => {

    try {

        const { data: response_data } = await api.get(`/private/livro_caixa/${uuid}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Adicionar = async (dados = {}) => {

    try {

        // pega os dados do administrador logado
        const administrador = AuthService.getUserData();

        // mescla os dados
        const data = {
            ...dados,
            administrador_uuid: administrador?.uuid,
        }

        const { data: response_data } = await api.post(`/private/livro_caixa`, data, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Atualizar = async (uuid = '', dados = {}) => {

    try {

        const { data: response_data } = await api.put(`/private/livro_caixa/${uuid}`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Saldo = async () => {

    try {

        const { data: response_data } = await api.get(`/private/livro_caixa/saldo`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}


const CaixaRequests = {
    listar: Listar,
    obter: Obter,
    adicionar: Adicionar,
    atualizar: Atualizar,
    saldo: Saldo,
};

export default CaixaRequests;