import React from "react";
import { BrowserRouter as Router, HashRouter, MemoryRouter, Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import history from "./hooks/history";

// SERVICES
import AuthService from "~/services/auth";

// PAGES
import PageHome from "~/pages/Private/Home";
import PageLogin from "~/pages/Public/Login";

import PageCadastroBicas from "~/pages/Private/Cadastros/BicasAgua";
import PageCadastroProdutos from "~/pages/Private/Cadastros/Produtos";
import PageCadastroAdministradores from "~/pages/Private/Cadastros/Administradores";

import PageVendas from "~/pages/Private/VendasV2";
import PageUsuarios from "~/pages/Private/Usuarios";
import PageUsuariosEnderecos from '~/pages/Private/UsuariosEnderecos';
import PageCaixa from "./pages/Private/Caixa";
import PageRelatorios from "./pages/Private/Relatorios";
import PageFechamentoCaixa from "./pages/Private/Relatorios/FechamentoCaixa";
import PageRelatorioDeVendas from "./pages/Private/Relatorios/RelatorioDeVendas";
import PageRelatorioRankingVendasCliente from "./pages/Private/Relatorios/RankingVendasPorCliente";
import PageRelatorioTransacoesOnline from "./pages/Private/Relatorios/RelatorioTransacoesOnline";
import PageConfiguracoes from "~/pages/Private/Configuracoes";
import PageRelatorioVendasContabilidade from '~/pages/Private/Relatorios/RelatorioVendasContabilidade';

/**
 * Proteje as rotas privadas do sistema
 */
const PrivateRoutes = () => {

    const session_validity = AuthService.checkUserSession();

    if (session_validity !== false) {
        return (
            <Routes>

                <Route path="/" element={<PageHome />} />

                <Route path="/cadastros/bicas" element={<PageCadastroBicas />} />
                <Route path="/cadastros/produtos" element={<PageCadastroProdutos />} />
                <Route path="/cadastros/administradores" element={<PageCadastroAdministradores />} />

                <Route path="/vendas" element={<PageVendas />} />
                <Route path="/usuarios" element={<PageUsuarios />} />
                <Route path="/usuarios/enderecos" element={<PageUsuariosEnderecos />} />
                <Route path="/caixa" element={<PageCaixa />} />
                <Route path="/configuracoes" element={<PageConfiguracoes />} />

                <Route path="/relatorios" element={<PageRelatorios />} />
                <Route path="/relatorios/fechamento_de_caixa" element={<PageFechamentoCaixa />} />
                <Route path="/relatorios/relatorio_de_vendas" element={<PageRelatorioDeVendas />} />
                <Route path="/relatorios/ranking_vendas_por_cliente" element={<PageRelatorioRankingVendasCliente />} />
                <Route path="/relatorios/relatorio_transacoes_online" element={<PageRelatorioTransacoesOnline />} />
                <Route path="/relatorios/relatorio_vendas_contabilidade" element={<PageRelatorioVendasContabilidade />} />
            </Routes>
        )
    } else {
        // retorna para o login
        return <Navigate to="/" />;
    }

};

/**
 * Rotas publicas do sistema
 */
const PublicRoutes = () => {

    // coloca o usenavigate no root
    window.useNavigate = useNavigate();

    // localização atual
    const location = useLocation();

    // verifica se já está logado
    const session_validity = AuthService.checkUserSession();

    // redireciona pra seção logada
    if (session_validity !== false && location.pathname === '/') {
        // retorna para o login
        return <Navigate to="/app" />;
    }

    return (
        <Routes>
            <Route path="/" element={<PageLogin />} />
            <Route path="/app/*" element={<PrivateRoutes />} />
        </Routes>
    );
};

/**
 * Exportação padrão
 */
export default () => {
    return (
        <HashRouter history={history}>
            <PublicRoutes />
        </HashRouter>
    );
};
