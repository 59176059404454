import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// SERVICES
import AuthService from "~/services/auth";
// import SetupService from "~/services/setup";
// import UsuariosRealmService from "~/services/realm/usuarios";
// import SincronizadorService from "~/services/sincronizador";

// UTILS
import moment from "~/utils/moment";

// COMPONENTES GERAIS
import Input from '~/components/Inputs/Normal/Normal';
import ButtonPrimary from '~/components/Buttons/Normal/Primary';

// COMPONENTES
import { BoxLogin, Copy, Section, ImageLogo } from "./styles.js";

// IMAGENS
import LogoEscura from "~/images/logo-hidrocaldas.png";
import NotificacaoUtil from "~/utils/notificacao.js";

export default function PageLogin() {
    const navigate = useNavigate();

    const InputSenhaRef = useRef(null);

    const [Email, setEmail] = useState(AuthService.getLastUserLogged());
    const [Senha, setSenha] = useState("");

    const SubmitForm = async (e) => {
        e.preventDefault();

        try {

            const Login = await AuthService.login(Email, Senha);

            if (Login) {
                // atualiza o último usuário logado
                await AuthService.setLastUserLogged(Email);

                // redireciona
                navigate('/app');
            }

        } catch (error) {
            NotificacaoUtil.error({
                msg: error.message
            });
        }

    };

    /**
     * Carrega a listagem de usuários quando carregar a página.
     */
    useEffect(() => {

    }, []);

    return (
        <BoxLogin>
            <center>
                <ImageLogo src={LogoEscura}></ImageLogo>
            </center>

            <Section>
                <form onSubmit={SubmitForm}>
                    <Input
                        ref={InputSenhaRef}
                        placeholder="E-mail"
                        type="email"

                        value={Email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <br />
                    <Input
                        ref={InputSenhaRef}
                        placeholder="Senha"
                        type="password"

                        value={Senha}
                        onChange={(event) => setSenha(event.target.value)}
                    />

                    <ButtonPrimary
                        type="submit"
                        style={{
                            width: '100%',
                            marginTop: '15px',
                            marginBottom: '25px',
                        }}>
                        Entrar
                    </ButtonPrimary>

                    <Copy>
                        © {moment().format("Y")} All Rights Reserved.
                    </Copy>
                </form>
            </Section>
        </BoxLogin>
    );
}
