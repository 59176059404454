import React, { useEffect, forwardRef, useImperativeHandle, useState, useRef } from 'react';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles.css';

// UTILS
import moment from "~/utils/moment";

// COMPONENTES GERAIS
import Modal from "~/components/Modal";

// STYLED COMPONENTS
import { Main, LabelObs } from "./styles";

// STATUS DEFAULT
export const DEFAULT_STATUS_OK = 'ok';
export const DEFAULT_STATUS_ABORTED = 'aborted';

const DateCalendarModalV2 = forwardRef((

    ({
        onOpen = () => { },
        onClose = () => { },

        animateOpacity = true,
        animateTranlate = true,

    }, ref) => {

        /**
         * Modal aberto?
         */
        const [isVisible, setIsVisible] = useState(false);

        /**
         * Data atual.
         */
        const [CurrentDate, setCurrentDate] = useState(null);

        /**
         * Data mínima.
         */
        const [MinDate, setMinDate] = useState(null);

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                RequestDate: onRequestDate
            }
        ));

        /**
         * Função que solicita a data.
         */
        const onRequestDate = (data = {
            current_date: null,
            min_date: null
        }) => {

            if (data?.min_date) {

                const min_date_moment = moment(data?.min_date, 'YYYY-MM-DD');

                const min_date_js = min_date_moment?.toDate();

                if (min_date_js) {
                    setMinDate(min_date_js);
                }

            }

            if (data?.current_date) {

                const data_moment = moment(data?.current_date, 'YYYY-MM-DD');

                const curr_date_js = data_moment?.toDate();

                if (curr_date_js) {
                    setCurrentDate(curr_date_js);
                }

            }

            /**
             * Mostra o modal.
             */
            setIsVisible(true);

            /**
             * Retorna a promessa
             */
            return new Promise((resolve, reject) => {
                window.ResolvePromiseLoginModal = resolve;
            });

        }

        /**
         * Ao selecionar a data
         */
        const onChangeDate = (date) => {
            const data_moment = moment(date);

            const data_formatada = data_moment?.format('YYYY-MM-DD');

            if (data_formatada) {
                ResolvePromiseDate(DEFAULT_STATUS_OK, data_formatada);
            }
        }

        /**
         * Quando clicar no fechar.
         */
        const onRequestAbort = () => {
            ResolvePromiseDate(DEFAULT_STATUS_ABORTED);
        }

        /**
         * Função que retorna a data.
         */
        const ResolvePromiseDate = (status = DEFAULT_STATUS_OK, date = null) => {
            setIsVisible(false);
            if (window.ResolvePromiseLoginModal) {
                window.ResolvePromiseLoginModal({ status: status, date: date });
                window.ResolvePromiseLoginModal = null;
            }
        }

        /**
         * Dispara as ações ao abrir e fechar.
         */
        useEffect(() => {
            if (isVisible == true) {
                onOpen();
            }
            return () => {
                if (isVisible == true) {
                    onClose();
                }
            }
        }, [isVisible]);

        return (
            <Modal
                isVisible={isVisible}
                setVisible={onRequestAbort}

                animateOpacity={animateOpacity}
                animateTranlate={animateTranlate}

                closeOnClickOutside={false}
                closeButtonVisible={true}
                closeButtonColor="#999"

                width={442}
                height={510}
            >
                <Main>

                    <Calendar
                        value={CurrentDate}

                        minDate={MinDate}

                        onClickDay={(val, event) => onChangeDate(val)}
                    />

                </Main>
            </Modal>
        )


    }

));

export default DateCalendarModalV2;