import styled from 'styled-components';
import ButtonOutlineSmallPrimary from 'components/ButtonsOutline/Small/Primary';
import ButtonSmallPrimary from '~/components/Buttons/Small/Primary';
import THEME_CONSTS from '~/consts/theme';

export const LabelInput = styled.div`
    font-family: 'Roboto Condensed';
    font-size:17px;
    margin-left:10px;
    margin-bottom:5px;
`;


export const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 22px;
    gap: 20px;
`;

export const ButtonEveryPeriod = styled(ButtonOutlineSmallPrimary)`

    color: ${ (props)  => props.backgroundColor != '' ? '#FFF' : THEME_CONSTS.BUTTON_PRIMARY_BG_COLOR  };
    background-color: transparent ;
    border: 1px solid ${THEME_CONSTS.BUTTON_PRIMARY_BG_COLOR};

    // reseta o hover antigo
    background: ${ (props)  => props.backgroundColor != '' ? props => props.backgroundColor : 'none'  };
    width: 100%;
`;

export const ButtonRelatorio = styled(ButtonSmallPrimary)`
    min-width:345px;
    margin:10px;
    background-color: ${ (props)  => props.backgroundColorFocus != '' && props.backgroundColorFocus  };

`;


