import React, { useMemo, useEffect, useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6 } from '~/components/Bootstrap/Col';
import InputGroup from '~/components/Bootstrap/InputGroup';
import Label from '~/components/Bootstrap/Label';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

// UTILS
import numeral from '~/utils/numeral';
import Utils from '~/utils';
import NotificacaoUtil from '~/utils/notificacao';

// SERVICES
import APIRequests from "~/services/requests_api";

const ModalCadastroEdicao = forwardRef(
    ({
        onConfirmCadEdit = () => { }
    }, ref) => {

        const [isVisible, setVisible] = useState(false);

        const [Preco, setPreco] = useState('');

        const onRequestConfirm = async () => {

            try {
                /* REMOVO TODOS OS PONTOS E SUBSTITUO VIRGULA POR PONTO */
                const NPreco = parseFloat(Preco?.toString()?.replace(/[\.]/g, "")?.replace(',', '.') || 0);

                if (NPreco <= 0) {
                    NotificacaoUtil.error({
                        msg: `Informe o preço do litro`,
                    });
                    return;
                }

                const objeto_enviar = {
                    preco_venda_litro: NPreco,
                }

                const retorno_api = await APIRequests.configs.atualizar(1, objeto_enviar);

                if (retorno_api?.id) {

                    NotificacaoUtil.success({
                        msg: `Preço do Litro atualizado com sucesso`,
                    });

                    // fecha o modal
                    onRequestClose();

                    // dispara a função externa
                    onConfirmCadEdit();

                } else {
                    NotificacaoUtil.error({
                        msg: `Erro ao atualizar o Preço do Litro`,
                        timeout: 3500,
                    });
                }

            } catch (error) {
                NotificacaoUtil.error({
                    msg: error.message,
                    timeout: 3500,
                });
            }

        }

        const onRequestOpen = async (e) => {

            let visible = true;

            const dados_produto = await APIRequests.configs.obter(1);

            if (dados_produto.id) {

                const preco_venda_litro_formatado = Utils.FormataValor(dados_produto.preco_venda_litro, false);

                setPreco(preco_venda_litro_formatado);

            } else {
                visible = false;
                NotificacaoUtil.error({
                    msg: 'Falha ao carregar o Preço do Litro.'
                });
            }

            if (visible) {
                setVisible(visible);
            }

        }

        const onRequestClose = () => {
            setVisible(false);

            // reseta o estado
            setPreco('');
        }

        const onKeyDown = (e) => {

            // pega o código pressionado
            const code = e.which !== false ? e.which : e.keyCode;

            // stop events
            if (code == 13 || code == 27) {
                e.preventDefault();
            }

            if (code == 13) {
                onRequestConfirm();
            }

            if (code == 27) {
                onRequestClose();
            }

        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <Modal
                isVisible={isVisible}
                setVisible={onRequestClose}
                closeButtonVisible={false}
                closeOnClickOutside={false}
                width={420}
                height={215}
            >
                <Main>
                    <ModalHeader>
                        Preço do Litro
                    </ModalHeader>

                    <Content>

                        <Row>
                            <ColSm12>
                                <InputNormal
                                    value={Preco}
                                    onKeyDown={onKeyDown}
                                    onChange={e => {
                                        const { value } = e.target;

                                        const preco = Utils.MascaraInputValor2Dig(value);

                                        if (preco !== -1) {
                                            setPreco(preco);
                                        }
                                    }}
                                />
                            </ColSm12>
                        </Row>

                        <Footer>

                            <ButtonSecondary onClick={onRequestClose}>
                                Fechar
                            </ButtonSecondary>

                            <ButtonPrimary onClick={onRequestConfirm}>
                                OK
                            </ButtonPrimary>

                        </Footer>

                    </Content>
                </Main>
            </Modal>
        )

    }
);

export default ModalCadastroEdicao;