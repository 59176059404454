import React, { useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';

// STYLED COMPONENTS
import { Background, ModalWrapper, ModalContent, MHeader, CloseModalButton } from './styles.js';

const Modal = ({
    id = '',
    isVisible = false,
    setVisible = () => { },

    style = {},

    animateTranlate = true,
    animateOpacity = true,

    // preventKeypress = true,
    closeButtonVisible = true,
    closeButtonColor = '#fff',
    closeOnClickOutside = true,

    backgroundColor = '#fff',
    fontColor = '#333',

    width = 650,
    height = 400,

    children
}) => {
    const modalRef = useRef(null);

    const animation_opacity = useSpring({
        config: {
            duration: 350
        },
        opacity: animateOpacity == true ? (isVisible ? 1 : 0.5) : 1,
    });

    const animation = useSpring({
        config: {
            duration: 250
        },
        transform: animateTranlate == true ? (isVisible ? `translateY(0%)` : `translateY(-5%)`) : `translateY(0%)`,
    });

    const handleCloseModal = e => {
        if (modalRef.current === e.target) {
            setVisible(false);
            
        }
    };

    useEffect(() => {
        if (isVisible) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [isVisible]);

    return (
        <>
            {isVisible ? (
                <animated.div id={id ?? ''} style={animation_opacity} className="modal" >
                    <Background onClick={closeOnClickOutside == true ? handleCloseModal : null} ref={modalRef}>
                        <animated.div style={animation}>
                            <ModalWrapper isVisible={isVisible} width={width} height={height} style={style} background={backgroundColor}>
                                <ModalContent fontColor={fontColor}>
                                    {children}
                                </ModalContent>

                                {closeButtonVisible && (
                                    <CloseModalButton
                                        color={closeButtonColor}
                                        aria-label='Close modal'
                                        onClick={() => setVisible(false)}
                                    >
                                        <i className="fal fa-times"></i>
                                    </CloseModalButton>
                                )}
                            </ModalWrapper>
                        </animated.div>
                    </Background>
                </animated.div>
            ) : null
            }
        </>
    );
}

export const ModalHeader = MHeader;

export default Modal;
