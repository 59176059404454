import styled from 'styled-components';

export const Main = styled.div`
    font-family: 'Roboto Condensed';
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Content = styled.div`
    width: calc(100% - (20px * 2));
    padding: 20px;
    height: 75%;
    display: flex;
    flex-direction: column;
`;

export const TabContainer = styled.div`
    height: calc(100% - 51px);
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
    }
      
    ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #0F669B;
    }
`

export const ColumnsContainer = styled.div`
    display: flex;
    flex: 1;
`;

export const PhotoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: ${props => props.width ? `${props.width}px` : ''};
    height: ${props => props.height ? `${props.height}px` : ''};

    margin-right: 20px;
    margin-bottom: 10px;
`;

export const Photo = styled.img`
    width: 98%;
    /* height: 768px; */
    
    padding: 3px;

    border-radius: 3px;

    border: 1px solid #ccc;
`;

export const Footer = styled.div`
    width: calc(100% - (20px * 2));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;

    button {
        margin-right: 20px;
    }

    button:last-child {
        margin-right: 0px;
    }
`;

export const MsgLstNone = styled.div`
    color: #555;
    font-size: 15px;
    text-align: center;
    margin-top: 15px;
`;