import styled from 'styled-components';
import Base from "./Base";

import THEME_CONSTS from '~/consts/theme';

const ButtonOutlineSmallPrimary = styled(Base)`
    color: ${THEME_CONSTS.BUTTON_PRIMARY_BG_COLOR};
    background-color: transparent;
    border: 1px solid ${THEME_CONSTS.BUTTON_PRIMARY_BG_COLOR};

    // reseta o hover antigo
    background: none;

    &:hover {
        color: ${THEME_CONSTS.BUTTON_PRIMARY_FONT_COLOR};
        background-color: ${THEME_CONSTS.BUTTON_PRIMARY_BG_COLOR};
    }
`;

export default ButtonOutlineSmallPrimary;