// API
import api from '~/services/api';

const Listar = async (query = '', limit = 100) => {

    try {

        const uquery = `query=${query}`
        const ulimit = `limit=${limit}`

        const query_params = `?${uquery}&${ulimit}`

        const { data: response_data } = await api.get(`/private/bicas_dagua${query_params}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Obter = async (uuid = '') => {

    try {

        const { data: response_data } = await api.get(`/private/bicas_dagua/${uuid}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Adicionar = async (dados = {}) => {

    try {

        const { data: response_data } = await api.post(`/private/bicas_dagua`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Atualizar = async (uuid = '', dados = {}) => {

    try {

        const { data: response_data } = await api.put(`/private/bicas_dagua/${uuid}`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Status = async (uuid = '') => {

    try {

        const { data: response_data } = await api.get(`/private/bicas_dagua/${uuid}/status`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}


const BicasDAguaRequests = {
    listar: Listar,
    obter: Obter,
    adicionar: Adicionar,
    atualizar: Atualizar,
    status: Status,
};

export default BicasDAguaRequests;