import React, { useCallback, useRef, useMemo, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Form } from '@unform/web';


// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";
import InputGroup, { InputGroupAddon, InputGroupButton } from "~/components/Bootstrap/InputGroup";
import InputSmall from "~/components/Inputs/Normal/Small";
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import Row from '~/components/Bootstrap/Row';
// STYLED COMPONENTS
import { Main, Content, Footer } from "../styles";

// TABLE
import Table from '~/components/TableR';

// BUTONS
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

// UTILS
import NotificacaoUtil from '~/utils/notificacao';
import Utils from "~/utils";

// SERVICES
import APIRequests from "~/services/requests_api";


const TabCreditoBonus = ({ UUID }) => {


    /**
    * Dados de paginação para crédito bonus 
    */
    const [NavPageCountBonus, setNavPageCountBonus] = useState(0);
    const [NavCurrentPageBonus, setNavCurrentPageBonus] = useState(1);
    const [NavLimitPerPageBonus, setNavLimitPerPageBonus] = useState(10);
    const [NavTotalRowsBonus, setNavTotalRowsBonus] = useState(0);

    const [RegistrosBonus, setRegistrosBonus] = useState([]);


    const [SaldoCaixa, setSaldoCaixa] = useState(0);

    /**
     * Carrega os  registros de crédito carteira
     */
    const onRequestListaRegistrosCreditoBonus = useCallback(async () => {

        const dados_credito_bonus = await APIRequests.credito_bonus.listar(UUID, NavCurrentPageBonus, NavLimitPerPageBonus);

        if (dados_credito_bonus.results) {
            setNavPageCountBonus(dados_credito_bonus.metadata.page.total);
            setNavTotalRowsBonus(dados_credito_bonus.metadata.total);

            /**
             * Carrega os dados adicionais do usuário
             */
            setRegistrosBonus(dados_credito_bonus.results);
        }
    }, [
        NavLimitPerPageBonus,
        NavCurrentPageBonus,
        UUID
    ]);

    /**
     * Buscando saldo
     */
     const onRequestSaldoBonus = useCallback(async () => {

        const retorno = await APIRequests.credito_bonus.saldo(UUID);

        if (retorno) {
            /**
             * Carrega os dados adicionais do usuário
             */
            setSaldoCaixa(retorno.saldo);
        }
    }, [
        NavLimitPerPageBonus,
        NavCurrentPageBonus,
        UUID
    ]);


    /**
    * Quando navegar na tabela de carteira
    */
    const onTableNavChangeBonus = useCallback((data) => {
        setNavLimitPerPageBonus(data.limit);
        setNavCurrentPageBonus(data.page + 1);
    }, [])


    /**
    * Dispara requisições para backend.
    */
    useEffect(() => {
        let timeout = setTimeout(() => {
            onRequestListaRegistrosCreditoBonus();
            onRequestSaldoBonus();

        }, 100);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        }
    }, [
        NavLimitPerPageBonus,
        NavCurrentPageBonus,
        UUID,
    ]);


    /**
     * Colunas da tabela
     */
    const TableColumns = useMemo(() => [
        {
            accessor: 'id',
            width: 20,
            disableSortBy: true,
            Header: () => (
                <center>
                    #
                </center>
            ),

            Cell: ({ value }) => {
                return (
                    <center>
                        {value}
                    </center>
                )
            }

        },
        {
            Header: 'Tipo',
            accessor: 'tipo',
            disableSortBy: true,
            width: 30,

            Header: () => (
                <center>
                    Tipo
                </center>
            ),

            Cell: ({ value }) => {
                return (
                    <center>
                        {value}
                    </center>
                )
            }
        },
        {
            Header: 'Histórico',
            accessor: 'historico',
            disableSortBy: true,
        },
        {
            Header: 'Entrada',
            accessor: 'entrada',
            width: 30,
            disableSortBy: true,
            Header: () => (
                <center>
                    Entrada
                </center>
            ),
            Cell: ({ value }) => {
                return (
                    <center>
                        {Utils.FormataValor(value)}
                    </center>
                )
            }
        },
        {
            Header: 'Saída',
            accessor: 'saida',
            width: 30,
            disableSortBy: true,
            Header: () => (
                <center>
                    Saída
                </center>
            ),
            Cell: ({ value }) => {
                return (
                    <center>
                        {Utils.FormataValor(value)}
                    </center>
                )
            }
        },
        {
            Header: 'Data',
            accessor: 'criado_em',
            width: 60,
            disableSortBy: true,
            Header: () => (
                <center>
                    Data
                </center>
            ),
            Cell: ({ value }) => {
                return (
                    <center>
                        {Utils.FormataDataHora(value)}
                    </center>
                )
            }
        },
    ], []);

    const TableInitialSortBy = [{
        id: 'criado_em',
        desc: true
    }]

    return (
        <>
            <Row>
                <ColSm3>
                    <InputGroup small>
                        <InputGroupAddon>
                            SALDO
                        </InputGroupAddon>
                        <InputSmall
                            style={{ color: SaldoCaixa > 0 ? '#41924c' : '#b14545' }}
                            value={Utils.FormataTotal(SaldoCaixa)}
                            disabled
                        />
                    </InputGroup>
                </ColSm3>
            </Row>

            <Table
                nostriped

                initialSortBy={TableInitialSortBy}

                pageIndex={NavCurrentPageBonus - 1}
                pageCount={NavPageCountBonus}
                totalRows={NavTotalRowsBonus}

                data={RegistrosBonus}
                columns={TableColumns}

                onChangeNav={onTableNavChangeBonus}
            />
        </>
    );
}



export default TabCreditoBonus;