import styled from 'styled-components';

export const Main = styled.div`
    font-family: 'Roboto Condensed';
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Content = styled.div`
    width: calc(100% - (20px * 2));
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const PhotoContainer = styled.div`
    width: calc(100%);
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin-bottom: 10px;
`;

export const Photo = styled.img`
    width: 230px;
    height: 230px;
    
    padding: 3px;

    border-radius: 3px;

    border: 1px solid #ccc;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 22px;
`;