import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import debounce from 'lodash/debounce';

// COMPONENTE
import ReactAsyncSelect from '~/components/ReactSelect/async';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12 } from '~/components/Bootstrap/Col';

// STYLED COMPONENTS
import { StepTitle, Content, Footer } from "~/pages/Private/Caixa/components/ModalNovoCreditoUsuario/styles";

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary'

// SERVICES
import APIRequests from "~/services/requests_api";

const Etapa1NovoCreditoUsuario = ({
    data = {},
    onConfirm = async (data) => { },
    onBack = async () => { },
}) => {

    /**
     * Array de usuários localizados.
     */
    const [SelectedUser, setSelectedUser] = useState({
        ...data?.usuario || {}
    });

    /**
     * Botão de avançar, está bloqueado?
     */
    const Blocked = !SelectedUser?.uuid;

    /**
     * Função que é disparada depois de pesquisar
     */
    const goLoadOptions = React.useCallback(
        debounce((input_value, callback) => {

            /**
             * Pega os dados da api.
             * !Não da para usar await, precisa usar o then por causa do debounce
             */
            APIRequests.usuarios.listar({
                query: input_value,
                page: 1,
                limit: 30,
                filter_cpf_only: 1,
            }).then(({ results }) => {
                /**
                 * Gera a array com os novos dados.
                 */
                const options = results.map((reg) => ({
                    label: `${reg.nome} - ${reg.cpf}`,
                    value: reg.uuid, ...reg
                }));

                /**
                 * Executa o callback pra mostrar os dados
                 */
                callback(options);
            });

        }, 700),
        []
    );

    return (
        <Content style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <StepTitle>
                    Selecionar o usuário
                </StepTitle>

                <Row>
                    <ColSm12>
                        <ReactAsyncSelect
                            autoFocus
                            loadOptions={goLoadOptions}

                            {...(SelectedUser?.uuid ? {

                                value: {
                                    value: SelectedUser?.value || '',
                                    label: SelectedUser?.label || '',
                                }

                            } : {})}

                            placeholder="Digite o CPF/CNPJ para localizar o usuário"

                            onChange={value => {
                                onConfirm(value)
                                setSelectedUser(value)
                            }}
                        />
                    </ColSm12>
                </Row>
            </div>

            <Footer>

                <ButtonSecondary onClick={onBack}>
                    Fechar
                </ButtonSecondary>

                <ButtonPrimary onClick={() => onConfirm(SelectedUser)} disabled={Blocked}>
                    Próxima etapa
                </ButtonPrimary>

            </Footer>
        </Content>
    )

}

export default Etapa1NovoCreditoUsuario;