import styled from 'styled-components';

export const Main = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* padding: 20px; */
    padding-left: 35px;
    padding-right: 35px;

    text-align: center;

    font-size: 22px;
`;

export const LabelObs = styled.span`
    font-size: 15px;
    margin-top: -15px;
    margin-bottom: 20px;

    color: #444;
`;