import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";

// SERVICES
import APIRequests from "~/services/requests_api";
import AuthService from "~/services/auth";



// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle, BasePageButtonsContainer, BasePageTopLineSeparator } from "~/components/BasePage";

// BOOTSTRAP
import Row from "~/components/Bootstrap/Row";
import { ColSm2, ColSm3, ColSm7, ColSm9 } from "~/components/Bootstrap/Col";

// TABLE
import Table from '~/components/TableR';

// COMPONENTES GERAIS
import InputSmall from "~/components/Inputs/Normal/Small";
import SearchInput from "~/components/SearchInput";
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from '~/components/Buttons/Small/Primary';

// COMPONENTES INTERNOS
import SuccessError from "./components/SuccessError";
import ModalCadastroEdicao from "./components/ModalCadEdit";
import ModalHistoricoCreditos from "./components/ModalHistoricoCreditos";

// UTILS
import Utils from "~/utils";
import moment from "~/utils/moment";

export default function PageUsuarios() {

    const RefModalCad = useRef(null);
    const RefModalHistoricoCreditos = useRef(null);

    /**
     * Dados de navegação
     */
    const [NavSorted, setNavSorted] = useState([]);
    const [NavPageCount, setNavPageCount] = useState(0);
    const [NavCurrentPage, setNavCurrentPage] = useState(1);
    const [NavLimitPerPage, setNavLimitPerPage] = useState(10);
    const [NavTotalRows, setNavTotalRows] = useState(0);

    /**
     * Lista de Usuarios
     */
    const [Usuarios, setUsuarios] = useState([]);

    const [Pesquisa, setPesquisa] = useState('');
    const [Pesquisar, setPesquisar] = useState(false);

    /**
     * Carrega os Usuarios da
     */
    const goLoadUsuarios = useCallback(async () => {

        const request = await APIRequests.usuarios.listar({
            query: Pesquisa,
            page: NavCurrentPage,
            limit: NavLimitPerPage,
            sorted: NavSorted,
            filter_cpf_only: 0
        });

        if (request.results) {
            setUsuarios(request.results);
            setNavPageCount(request.metadata.page.total);
            setNavTotalRows(request.metadata.total);
        }

        setPesquisar(false);

    }, [
        Pesquisa,
        NavLimitPerPage,
        NavCurrentPage,
        NavSorted
    ]);

    /**
     * Quando navegar na tabela
     */
    const onTableNavChange = useCallback((data) => {
        setNavSorted(data.sortBy);
        setNavLimitPerPage(data.limit);
        setNavCurrentPage(data.page + 1);
    }, [])

    /**
     * Abre o modal para editar.
     */
    const onRequestEditAdministrador = (uuid) => {
        RefModalCad?.current?.open(uuid);
    }

    /**
     * Abre o modal para editar.
     */
    const onRequestOpenHistoricoCreditos = (uuid) => {
        RefModalHistoricoCreditos?.current?.open(uuid);
    }

    /**
     * Ao confirmar o cadastro ou edição do Administrador.
     */
    const onConfirmCadEditAdministrador = () => {
        goLoadUsuarios();
    }

    /**
     * Dispara requisições para backend.
     */
    useEffect(() => {
        let timeout = setTimeout(() => {
            goLoadUsuarios();
        }, 100);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        }
    }, [
        Pesquisa,
        NavLimitPerPage,
        NavCurrentPage,
        NavSorted
    ]);

    /**
     * Colunas da tabela
     */
    const TableColumns = useMemo(() => [
        {
            accessor: 'uuid',
            width: 30,
            disableSortBy: true,
            Header: () => (
                <center>
                    #
                </center>
            ),
            Cell: ({ value }) => {
                return (
                    <center>
                        {AuthService.checkPermission('usuarios', 'editar') ? (
                            <ButtonPrimary onClick={() => onRequestEditAdministrador(value)}>
                                <i className="fas fa-pencil" />
                            </ButtonPrimary>
                        ) : (
                            <ButtonPrimary disabled="disabled" onClick={() => onRequestEditAdministrador(value)}>
                                <i className="fas fa-pencil" />
                            </ButtonPrimary>
                        )}

                        <ButtonPrimary onClick={() => onRequestOpenHistoricoCreditos(value)}>
                            <i className="fal fa-file-invoice-dollar" />
                        </ButtonPrimary>
                    </center>
                )
            }
        },
        {
            Header: 'Nome',
            accessor: 'nome'
        },
        {
            Header: 'CPF/CNPJ',
            accessor: 'cpf',
            width: 150
        },
        {
            Header: 'Email',
            accessor: 'email',
            width: 200,
        },
        {
            Header: 'Celular',
            accessor: 'celular',
            width: 120
        },
        {
            Header: 'Munícipe',
            accessor: 'municipe',
            width: 100,
            Cell: ({ value, row }) => {
                const { original } = row;
                return (
                    <>
                        {(original.municipe === true && original?.enderecos_aguardando?.length > 0) && (
                            <SuccessError />
                        )}

                        {(original.municipe === true && original?.enderecos?.length > 0 && original?.enderecos_aguardando?.length == 0) && (
                            <SuccessError success />
                        )}

                        {original.municipe ? 'Sim' : 'Não'}
                    </>
                )
            }
        },
    ], []);

    const TableInitialSortBy = [{
        id: 'nome',
        desc: false
    }]

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Usuários
                    </BasePageTitle>
                </BasePageTitleContainer>

                <BasePageBox id="box">
                    <Row>
                        <ColSm9 />
                        <ColSm3>
                            <SearchInput
                                inputType="small"

                                onSearch={(text) => {
                                    setNavCurrentPage(1)
                                    setPesquisar(true)
                                    setPesquisa(text)
                                }}
                            />
                        </ColSm3>
                    </Row>

                    <div style={{ marginTop: 10 }}>
                        <Table
                            nostriped

                            initialSortBy={TableInitialSortBy}

                            pageIndex={NavCurrentPage - 1}
                            pageCount={NavPageCount}
                            totalRows={NavTotalRows}

                            data={Usuarios}
                            columns={TableColumns}

                            onChangeNav={onTableNavChange}
                        />
                    </div>
                </BasePageBox>
            </BasePageContainer>

            <ModalCadastroEdicao
                ref={RefModalCad}
                onConfirmCadEdit={onConfirmCadEditAdministrador}
            />

            <ModalHistoricoCreditos
                ref={RefModalHistoricoCreditos}
            />

        </BasePageMain >
    );
}
