import React, { useState, useEffect, useRef, useMemo } from "react";

// SERVICES
import APIRequests from "~/services/requests_api";
import AuthService from "~/services/auth";

// COMPONENTES GERAIS
import SearchInput from "~/components/SearchInput";

import Row from "~/components/Bootstrap/Row";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle, BasePageButtonsContainer, BasePageTopLineSeparator } from "~/components/BasePage";

// TABLE
import { Table, Thead, Tbody, Tr, Th, Td } from '~/components/Table';

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from '~/components/Buttons/Small/Primary';

// COMPONENTES INTERNOS
import ModalCadastroEdicao from "./components/ModalCadEdit";
import ModalValorLitro from "./components/ModalValorLitro";

// UTILS
import Utils from "~/utils";
import { ColSm3, ColSm9 } from "~/components/Bootstrap/Col";

export default function PageCadastroProdutos() {

    const RefModalCad = useRef(null);
    const RefModalPLitro = useRef(null);

    /**
     * Lista de produtos
     */
    const [Produtos, setProdutos] = useState([]);

    const [Pesquisa, setPesquisa] = useState('');
    const [Pesquisar, setPesquisar] = useState(false);

    /**
     * Carrega os produtos da
     */
    const goLoadProdutos = async () => {
        const produtos = await APIRequests.produtos.listar(Pesquisa);

        if (produtos.results) {
            setProdutos(produtos.results);
        }
    }

    /**
     * Abre o modal para inserir.
     */
    const onRequestAddProduto = () => {
        RefModalCad?.current?.open();
    }

    /**
     * Abre o modal para editar.
     */
    const onRequestEditProduto = (uuid) => {
        RefModalCad?.current?.open(uuid);
    }

    const onRequestUpdatePrecoLitro = () => {
        RefModalPLitro?.current?.open();
    }

    /**
     * Carrega os produtos a cada pesquisa
     */
    useEffect(() => {
        if (Pesquisar === true) {
            goLoadProdutos();
        }
    }, [Pesquisa, Pesquisar]);

    /**
     * Carrega a listagem de usuários quando carregar a página.
     */
    useEffect(() => {
        goLoadProdutos();
    }, []);

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Cadastro de Produtos
                    </BasePageTitle>
                </BasePageTitleContainer>

                <BasePageTopLineSeparator />

                <BasePageButtonsContainer>
                    {AuthService.checkPermission('produtos', 'adicionar') && (
                        <ButtonPrimary onClick={onRequestAddProduto}>
                            Adicionar
                        </ButtonPrimary>
                    )}

                    {AuthService.checkPermission('produtos', 'atualizar_preco_litro') && (
                        <ButtonPrimary onClick={onRequestUpdatePrecoLitro}>
                            Atualizar preço litro
                        </ButtonPrimary>
                    )}
                </BasePageButtonsContainer>

                <BasePageBox id="box">
                    <Row>
                        <ColSm9 />
                        <ColSm3>
                            <SearchInput
                                inputType="small"
                                onChange={(e) => setPesquisar(true)}
                                onSearch={(text) => setPesquisa(text)}
                            />
                        </ColSm3>
                    </Row>

                    {/* <div style={{ marginTop: -12, marginLeft: -14, marginRight: -14 }}> */}
                    <div style={{ marginTop: 10 }}>
                        <Table nostriped>
                            <Thead>
                                <Tr>
                                    <Th width={40}>
                                        <center>#</center>
                                    </Th>
                                    <Th>Descrição</Th>
                                    <Th width={150}>Preço por litro (global)</Th>
                                    <Th width={100}>Litros</Th>
                                    <Th width={100}>Situação</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {Produtos.map((prod) => {
                                    return (
                                        <Tr key={prod.uuid}>
                                            <Td width={40}>
                                                <center>
                                                    {AuthService.checkPermission('produtos', 'editar') ? (
                                                        <ButtonPrimary onClick={() => onRequestEditProduto(prod.uuid)}>
                                                            <i className="fas fa-pencil" />
                                                        </ButtonPrimary>
                                                    ) : (
                                                        <ButtonPrimary disabled="disabled" onClick={() => onRequestEditProduto(prod.uuid)}>
                                                            <i className="fas fa-pencil" />
                                                        </ButtonPrimary>
                                                    )}
                                                </center>
                                            </Td>
                                            <Td>{prod.descricao}</Td>
                                            <Td width={150}>{Utils.FormataTotal(prod?.preco_litro || 0)}</Td>
                                            <Td width={100}>{parseFloat(prod?.litros || 0)}</Td>
                                            <Td width={100} style={{ textTransform: 'capitalize' }}>{prod.situacao}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </div>
                </BasePageBox>
            </BasePageContainer>

            <ModalCadastroEdicao
                ref={RefModalCad}
                onConfirmCadEdit={() => goLoadProdutos()}
            />

            <ModalValorLitro
                ref={RefModalPLitro}
                onConfirmCadEdit={() => goLoadProdutos()}
            />

        </BasePageMain >
    );
}
