import React from 'react';
import ReactImageZoom from 'react-image-zoom';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm4, ColSm6, ColSm8, ColSm12 } from '~/components/Bootstrap/Col';
import Label from '~/components/Bootstrap/Label';

// COMPONENTES GERAIS
import ButtonPrimary from '~/components/Buttons/Small/Primary';

// STYLED COMPONENTS
import { PhotoContainer, ColumnsContainer } from "../styles";

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import TextareaNormal from '~/components/Textareas/Normal';

// UTILS
import Utils from "~/utils";

// IMAGEM SEM FOTO
import ImageNoPhoto from '~/images/no-image-endereco.png'

const TabGeralEndereco = ({ DadosEndereco,  Observacao, setObservacao, SaldoCarteira, setTabVisible }) => {

  return (
    <>
      <ColumnsContainer>

        <PhotoContainer
            width={440}
            height={468}
        >
            <ReactImageZoom
                width={440}
                height={468}
                zoomPosition="original"
                img={DadosEndereco.foto_comprovante || ImageNoPhoto}
            />
        </PhotoContainer>

        <div style={{ width: '100%' }}>
            <Row>
                <ColSm6>
                    <Label>Saldo carteira usuário</Label>
                    <InputNormal disabled value={Utils.FormataTotal(SaldoCarteira)} />
                </ColSm6>
                <ColSm6>
                    <Label>Unidade consumidora</Label>
                    <InputNormal disabled value={DadosEndereco?.unidade_consumidora} />
                </ColSm6>
            </Row>
            <Row>
                <ColSm8>
                    <Label>Usuário</Label>
                    <InputNormal disabled value={DadosEndereco?.usuario?.nome} />
                </ColSm8>
                <ColSm4>
                    <Label>CPF/CNPJ Usuário</Label>
                    <InputNormal disabled value={DadosEndereco?.usuario?.cpf} />
                </ColSm4>
            </Row>

            <Row>
                <ColSm12>
                    <Label>Endereço</Label>
                    <TextareaNormal
                        disabled
                        rows={4}
                        value={
                            `${DadosEndereco?.logradouro}, ${DadosEndereco?.numero}\n` +
                            `${DadosEndereco?.bairro || ''} - ${DadosEndereco?.cep || ''}\n` +
                            `${DadosEndereco?.complemento || ''}\n` +
                            `${DadosEndereco?.municipio} - ${DadosEndereco?.uf}`
                        }
                        style={{ resize: 'vertical', minHeight: 72, maxHeight: 100 }}
                    />
                </ColSm12>
            </Row>

            <Row style={{marginTop: 25}}>
                <ColSm12>
                    <Label>Observações endereço</Label>
                    <TextareaNormal
                        rows={6}
                        resize='none'
                        autoComplete="none"

                        value={Observacao}
                        onChange={(e) => setObservacao(e.target.value)}
                    />
                </ColSm12>
            </Row>

            <Row style={{marginTop: 12}}>
                <ColSm12>Quantidade de usuários com a mesma unidade consumidora: <span style={{fontWeight: 'bold'}}>{DadosEndereco?.enderecos_iguais?.length}</span>
                    <ButtonPrimary onClick={() => setTabVisible('enderecos_iguais')} style={{width: 20, padding: 3, marginLeft: 6, fontSize: 10}}> 
                        <i className="fas fa-eye" /> 
                    </ButtonPrimary>    
                </ColSm12>
            </Row>

        </div>

      </ColumnsContainer>

    </>
  )
}

export default TabGeralEndereco;