import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useExpanded, useTable } from 'react-table';

import APIRequests from "~/services/requests_api";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle, BasePageButtonsContainer, BasePageTopLineSeparator } from "~/components/BasePage";

// TABLE
import { Table, Thead, Tbody, Tr, Th, Td } from '~/components/Table';

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from '~/components/ButtonsOutline/Small/Secondary';

export default function PageHome() {
    return (
        <BasePageMain>
            <NavigationBar />

            <BasePageContainer>


            </BasePageContainer>
        </BasePageMain >
    );
}
