import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import Label from '~/components/Bootstrap/Label';

// ETAPAS
import Etapa1NovoCreditoUsuario from './Etapas/Etapa1';
import Etapa2NovoCreditoUsuario from './Etapas/Etapa2';
import Etapa3NovoCreditoUsuario from './Etapas/Etapa3';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";
import DateCalendarModalV2 from '~/components/DateCalendarModalV2';

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';
import ButtonDanger from '~/components/Buttons/Normal/Danger';

// UTILS
import moment from '~/utils/moment';
import numeral from '~/utils/numeral';
import Utils from '~/utils';
import NotificacaoUtil from '~/utils/notificacao';

// SERVICES
import APIRequests from "~/services/requests_api";

const ModalNovoCreditoUsuario = forwardRef(
    ({
        onConfirm = () => { }
    }, ref) => {

        /**
         * Modal visível?
         */
        const [isVisible, setVisible] = useState(false);

        /**
         * Dados do usuário
         */
        const [DadosUsuario, setDadosUsuario] = useState({});
        const [DadosPagamento, setDadosPagamento] = useState({});

        /**
         * Enviando o formulário
         */
        const [Sending, setSending] = useState(false);

        /**
         * Indicador da etapa
         */
        const [Etapa, setEtapa] = useState(1);

        /**
         * Retorna a altura do modal
         */
        const getModalHeight = (etapa) => {
            const heights = {
                '1': 600,
                '2': 380,
                '3': 420,
            };
            return heights[etapa];
        }

        const onConfirmEtapa1 = (user = {}) => {
            setDadosUsuario(user);
            // botão de limpar user, dispara essa função tbm
            if (user) {
                setEtapa(2);
            }
        }

        const onConfirmEtapa2 = (data = {}) => {
            setDadosPagamento(data);
            setEtapa(3);
        }

        const onConfirmEtapa3 = async () => {

            if (Sending) {
                // aviso para aguardar
                // !precisa estar fora do if por que entra no finally e ele tira o loading
                NotificacaoUtil.warning({
                    msg: `Aguarde o processamento da soliticação anterior`,
                    timeout: 3500,
                });
                return;
            }

            try {

                setSending(true);

                if (!DadosUsuario?.uuid) {
                    throw new Error('Selecione um usuário para poder lançar o crédito');
                }

                if (!DadosPagamento?.valor > 0) {
                    throw new Error('Informe o valor do crédito a ser lançado');
                }

                const historico = `ADIÇÃO DE CRÉDITO MANUAL P/ ${DadosUsuario?.nome}`?.toUpperCase();

                const objeto_enviar = {
                    historico: historico,
                    usuario_uuid: DadosUsuario.uuid,
                    entrada: DadosPagamento.valor,
                    especie: DadosPagamento.especie,
                    saida: 0,
                    tipo: 'entrada',
                }

                const retorno_api = await APIRequests.caixa.adicionar(objeto_enviar);

                if (retorno_api?.id) {

                    NotificacaoUtil.success({
                        msg: `Crédito adicionado ao usuaário com sucesso`,
                    });

                    // fecha o modal
                    onRequestClose();

                    // dispara a função externa
                    onConfirm();

                } else {
                    NotificacaoUtil.error({
                        msg: `Erro ao adicionar o crédito`,
                        timeout: 3500,
                    });
                }

            } catch (error) {
                NotificacaoUtil.error({
                    msg: error.message,
                    timeout: 3500,
                });
            } finally {
                setSending(false);
            }
        }

        const onRequestOpen = async () => {
            setVisible(true);
        }

        const onRequestClose = () => {
            setDadosUsuario({});
            setDadosPagamento({});
            setEtapa(1);

            setVisible(false);
            setSending(false);
        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <Modal
                isVisible={isVisible}
                setVisible={onRequestClose}
                closeButtonVisible={false}
                closeOnClickOutside={false}
                width={620}
                height={getModalHeight(Etapa)}
            >
                <Main>
                    <ModalHeader>
                        Adicionar crédito para usuário
                    </ModalHeader>

                    {Etapa == 1 && <Etapa1NovoCreditoUsuario
                        data={{ pagamento: DadosPagamento, usuario: DadosUsuario }}
                        onConfirm={onConfirmEtapa1}
                        onBack={() => onRequestClose()}
                    />}

                    {Etapa == 2 && <Etapa2NovoCreditoUsuario
                        data={{ pagamento: DadosPagamento, usuario: DadosUsuario }}
                        onConfirm={onConfirmEtapa2}
                        onBack={() => setEtapa(1)}
                    />}

                    {Etapa == 3 && <Etapa3NovoCreditoUsuario
                        data={{ pagamento: DadosPagamento, usuario: DadosUsuario, sending: Sending }}
                        onConfirm={onConfirmEtapa3}
                        onBack={() => setEtapa(2)}
                    />}
                </Main>
            </Modal>
        )

    }
);

export default ModalNovoCreditoUsuario;