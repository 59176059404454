import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// SERVICES
import AuthService from "~/services/auth";
import APIRequests from "~/services/requests_api";

// COMPONENTES GERAIS
import Header from '~/components/Header';
import HeaderItem from '~/components/Header/Item';
import HeaderItemN1 from '~/components/Header/ItemN1';

export default function NavigationBar() {
    const navigate = useNavigate();

    /**
     * Quantidade de endereços pendentes.
     */
    const [QtdEnderecosPendentes, setQtdEnderecosPendentes] = useState('');

    /**
     * Nome do usuário logado
     */
    const [NomeUsuarioLogado, setNomeUsuarioLogado] = useState('USUÁRIO');

    const onRequestCadProdutos = () => {
        navigate('/app/cadastros/produtos');
    }

    const onRequestCadAdministradores = () => {
        navigate('/app/cadastros/administradores');
    }

    const onRequestCadBicas = () => {
        navigate('/app/cadastros/bicas');
    }

    const onRequestVendas = () => {
        navigate('/app/vendas');
    }

    const onRequestUsuarios = () => {
        navigate('/app/usuarios');
    }

    const onRequestUsuariosEnderecos = () => {
        navigate('/app/usuarios/enderecos');
    }

    const onRequestCaixa = () => {
        navigate('/app/caixa');
    }

    const onRequestRelatorios = () => {
        navigate('/app/relatorios');
    }

    const onRequestConfigs = () => {
        navigate('/app/configuracoes');
    }

    const onRequestLogout = async () => {
        // efetua o logout
        await AuthService.logout();

        // volta pro login
        navigate('/');
    }

    const goLoadQtdEnderecosPendentes = async () => {
        const qtd_pendentes = await APIRequests.enderecos.qtd_pendentes();

        if (qtd_pendentes > 0) {
            setQtdEnderecosPendentes(qtd_pendentes?.toString());
        } else {
            setQtdEnderecosPendentes('');
        }
    }

    const goLoadMeData = async () => {
        const me = await APIRequests.administradores.me();

        if (me.id) {
            // atualiza os dados do usuário no storage
            AuthService.setUserData(me);

            // atualiza o nome do usuário logado
            setNomeUsuarioLogado(me?.nome?.toUpperCase());
        }
    }

    const goLoadUsuarioLogado = () => {
        const usuario = AuthService.getUserData();
        setNomeUsuarioLogado(usuario?.nome?.toUpperCase());
    }

    /**
     * Carrega os endereços pendentes.
     */
    useEffect(() => {
        goLoadMeData();
        goLoadUsuarioLogado();
        goLoadQtdEnderecosPendentes();
    }, []);

    return (
        <Header>

            {(AuthService.checkModule('produtos') || AuthService.checkModule('administradores')  || AuthService.checkModule('bicas_dagua')  )  && (
                <HeaderItem icon="fal fa-book" title='CADASTROS' onClick={() => { }}>

                    {AuthService.checkModule('produtos') && (
                        <HeaderItemN1 title='PRODUTOS' icon="fal fa-glass" onClick={onRequestCadProdutos} />
                    )}

                    {AuthService.checkModule('administradores') && (
                        <HeaderItemN1 title='ADMINISTRADORES' icon="fal fa-user-crown" onClick={onRequestCadAdministradores} />
                    )}

                    {AuthService.checkModule('bicas_dagua') && (
                        <HeaderItemN1 title="BICAS D'ÁGUA" icon="fal fa-faucet-drip" onClick={onRequestCadBicas} />
                    )}

                </HeaderItem>
            )}


            {AuthService.checkModule('vendas') && (
                <HeaderItem icon="fal fa-file-invoice" title='VENDAS' onClick={onRequestVendas} />
            )}

            {AuthService.checkModule('usuarios') && (
                <HeaderItem icon="fal fa-users" title='USUÁRIOS' onClick={onRequestUsuarios} />
            )}

            {AuthService.checkModule('enderecos') && (
                <HeaderItem icon="fal fa-map-marked" title='ENDEREÇOS' badge={QtdEnderecosPendentes} onClick={onRequestUsuariosEnderecos} />
            )}

            {AuthService.checkModule('caixa') && (
                <HeaderItem icon="fal fa-cash-register" title='CAIXA' onClick={onRequestCaixa} />
            )}

            {AuthService.checkModule('relatorios') && (
                <HeaderItem icon="fal fa-analytics" title='RELATÓRIOS' onClick={onRequestRelatorios} />
            )}

            <div style={{ float: 'right' }}>

                <HeaderItem icon="fal fa-user" title={NomeUsuarioLogado} onClick={() => { }} dropdownMenuPosition="right">

                    {AuthService.checkModule('configuracoes') && (
                        <HeaderItemN1 icon="fal fa-cogs" title='CONFIGURAÇÕES' onClick={onRequestConfigs} />
                    )}
                    <HeaderItemN1 icon="fal fa-sign-out-alt" title='SAIR' onClick={onRequestLogout} />

                </HeaderItem>
            </div>
        </Header >
    );
}