// SUB
import AdministradoresRequests from './administradores';
import ProdutosRequests from './produtos';
import UsuariosRequests from './usuarios';
import BicasDAguaRequests from './bicas';
import EnderecosRequests from './enderecos';
import EnderecosLogsRequests from './enderecos_logs';
import ConfigsRequests from './configs';
import VendasRequests from './vendas';
import CaixaRequests from './caixa';
import ModulosRequests from './modulos';
import RelatoriosRequests from './relatorios';
import CreditoCarteiraRequests from './credito_carteira';
import CreditoBonusRequests from './credito_bonus';
import TransacoesOnlineRequests from './transacoes_online';



const APIRequests = {
    produtos: {
        listar: ProdutosRequests.listar,
        obter: ProdutosRequests.obter,
        adicionar: ProdutosRequests.adicionar,
        atualizar: ProdutosRequests.atualizar,
        apagar: ProdutosRequests.apagar,
    },

    administradores: {
        me: AdministradoresRequests.me,
        listar: AdministradoresRequests.listar,
        obter: AdministradoresRequests.obter,
        adicionar: AdministradoresRequests.adicionar,
        atualizar: AdministradoresRequests.atualizar,
    },

    vendas: {
        listar: VendasRequests.listar,
        obter: VendasRequests.obter,
        adicionar: VendasRequests.adicionar,
        atualizar: VendasRequests.atualizar,
    },

    caixa: {
        listar: CaixaRequests.listar,
        obter: CaixaRequests.obter,
        adicionar: CaixaRequests.adicionar,
        atualizar: CaixaRequests.atualizar,
        saldo: CaixaRequests.saldo
    },

    bicas_dagua: {
        listar: BicasDAguaRequests.listar,
        obter: BicasDAguaRequests.obter,
        adicionar: BicasDAguaRequests.adicionar,
        atualizar: BicasDAguaRequests.atualizar,
        status: BicasDAguaRequests.status,
    },

    usuarios: {
        listar: UsuariosRequests.listar,
        obter: UsuariosRequests.obter,
        // adicionar: UsuariosRequests.adicionar,
        atualizar: UsuariosRequests.atualizar,
    },

    enderecos: {
        listar: EnderecosRequests.listar,
        qtd_pendentes: EnderecosRequests.qtd_pendentes,
        obter: EnderecosRequests.obter,
        atualizar: EnderecosRequests.atualizar,
        aprovar: EnderecosRequests.aprovar,
        migracao_de_endereco: EnderecosRequests.migracao_de_endereco,
    },

    enderecos_logs: {
        listar: EnderecosLogsRequests.listar
    },

    configs: {
        obter: ConfigsRequests.obter,
        atualizar: ConfigsRequests.atualizar,
    },

    relatorios: {
        gerar_pdf: RelatoriosRequests.gerarPdf,
        gerar_xls: RelatoriosRequests.gerarXls,
    },

    modulos: {
        listar: ModulosRequests.listar,
    },

    credito_carteira: {
        listar: CreditoCarteiraRequests.listar, 
        saldo: CreditoCarteiraRequests.saldo
    },

    credito_bonus: {
        listar: CreditoBonusRequests.listar, 
        saldo: CreditoBonusRequests.saldo
    },

    transacoes_online: {
        listar: TransacoesOnlineRequests.listar, 
        saldo: TransacoesOnlineRequests.saldo
    }
};

export default APIRequests;