import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm6 } from '~/components/Bootstrap/Col';
import Label from '~/components/Bootstrap/Label';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

// UTILS
import Utils from '~/utils';
import NotificacaoUtil from '~/utils/notificacao';

// SERVICES
import APIRequests from "~/services/requests_api";
import EspeciesPagamentoService from '~/services/especies-pag';
import TextareaNormal from '~/components/Textareas/Normal';

const ModalFechamentoCaixa = forwardRef(
    ({
        onConfirm = () => { }
    }, ref) => {

        const RefInputValor = useRef(null);

        /**
         * Espécies de pagamento.
         */
        const EspeciesPag = EspeciesPagamentoService.especies;

        const [isVisible, setVisible] = useState(false);

        const [Valor, setValor] = useState('');
        const [Especie, setEspecie] = useState(EspeciesPag[0]);
        const [Observacoes, setObservacoes] = useState('');
        const [Sending, setSending] = useState(false);

        const onRequestConfirm = async () => {

            if(!Sending) {
                try {

                    setSending(true);

                    const NValor = parseFloat(Valor?.toString()?.replace(/[\.]/g, "")?.replace(',', '.') || 0);
    
                    if (NValor == 0) {
                        RefInputValor?.current?.focus();
                        throw new Error('Preencha o valor da movimentação');
                    }
    
                    const objeto_enviar = {
                        historico: 'FECHAMENTO DE CAIXA',
                        observacoes: Observacoes,
                        entrada: 0,
                        saida: NValor,
                        tipo: 'saida',
                        especie: Especie
                    }
    
                    const retorno_api = await APIRequests.caixa.adicionar(objeto_enviar);
    
                    setSending(false);

                    if (retorno_api?.id) {
    
                        NotificacaoUtil.success({
                            msg: `Fechamento de caixa realizado com sucesso`,
                        });
    
                        // fecha o modal
                        onRequestClose();
    
                        // dispara a função externa
                        onConfirm();
    
                    } else {
                        NotificacaoUtil.error({
                            msg: `Erro ao fechar o caixa`,
                            timeout: 3500,
                        });
                    }
    
                } catch (error) {
                    setSending(false);
                    NotificacaoUtil.error({
                        msg: error.message,
                        timeout: 3500,
                    });
                }
            }

        }

        const onRequestOpen = async () => {

            const retorno = await APIRequests.caixa.saldo();
            setValor(Utils.FormataTotal(retorno.saldo, false));

            setVisible(true);
        }

        const onRequestClose = () => {
            setVisible(false);

            // reseta o estado
            setValor('');
            setObservacoes('');
            setEspecie(EspeciesPag[0]);
        }


        const onKeyDown = (e) => {

            // pega o código pressionado
            const code = e.which !== false ? e.which : e.keyCode;

            // keys
            const key_enter = 13;

            if (code == key_enter) {
                e.preventDefault();
                onRequestConfirm();
            }

        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <>
                <Modal
                    isVisible={isVisible}
                    setVisible={onRequestClose}
                    closeButtonVisible={false}
                    closeOnClickOutside={false}
                    width={720}
                    height={380}
                >
                    <Main>
                        <ModalHeader>
                            Fechamento de Caixa
                        </ModalHeader>

                        <Content>

                            <Row>

                                <ColSm6>
                                    <Label>Valor</Label>
                                    <InputNormal
                                        ref={RefInputValor}
                                        value={Valor}
                                        onKeyDown={onKeyDown}
                                        onChange={e => {
                                            const { value } = e.target;

                                            const valor = Utils.MascaraInputValor2Dig(value);

                                            if (valor !== -1) {
                                                setValor(valor);
                                            }
                                        }}
                                    />
                                </ColSm6>

                                <ColSm6>
                                    <Label>Espécie</Label>
                                    <SelectNormal value={Especie} onChange={e => setEspecie(e.target.value)} onKeyDown={onKeyDown}>
                                        {EspeciesPag.map(especie => (
                                            <option key={especie} value={especie}>{especie}</option>
                                        ))}
                                    </SelectNormal>
                                </ColSm6>
                            </Row>

                            <Row>
                                <ColSm12>
                                    <Label>Observações</Label>
                                    <TextareaNormal
                                        rows={5}
                                        value={Observacoes}
                                        onChange={e => setObservacoes(e.target.value)}
                                        onKeyDown={onKeyDown}
                                    />
                                </ColSm12>
                            </Row>

                            <Footer>

                                <ButtonSecondary onClick={onRequestClose} disabled={Sending}>
                                    Fechar
                                </ButtonSecondary>

                                <ButtonPrimary onClick={onRequestConfirm} disabled={Sending}>
                                    OK
                                </ButtonPrimary>

                            </Footer>
                        </Content>
                    </Main>
                </Modal>

            </>
        )

    }
);

export default ModalFechamentoCaixa;