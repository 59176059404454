import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import debounce from 'lodash/debounce';

// SERVICES
import EspeciesPagamentoService from '~/services/especies-pag';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import Label from '~/components/Bootstrap/Label';

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// COMPONENTS
import ReactSelect from '~/components/ReactSelect';

// STYLED COMPONENTS
import { StepTitle, StepSubTitle, Content, Footer } from "~/pages/Private/Caixa/components/ModalNovoCreditoUsuario/styles";

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary'

// UTILS
import Utils from '~/utils';
import NotificacaoUtil from '~/utils/notificacao';

const Etapa2MigrarEndereco = ({
    data = {},
    onConfirm = async (data) => { },
    onBack = async () => { },
}) => {

    const onRequestConfirm = () => {
        onConfirm();
    }
    return (
        <Content style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <StepTitle>
                    Endereço selecionado
                </StepTitle>

                <Row>
                    <ColSm12>
                        <StepSubTitle>
                            <div>
                                <b>Usuário</b>: {data?.endereco?.usuario?.nome}
                            </div>
                            <div>
                                <b>CPF</b>: {data?.endereco?.usuario?.cpf}
                            </div>
                            <div>
                                <b>Endereço</b>: {`${data?.endereco?.logradouro} ${data?.endereco?.numero}, ${data?.endereco?.bairro}, ${data?.endereco?.municipio} - ${data?.endereco?.cep}`}
                            </div>
                        </StepSubTitle>
                    </ColSm12>
                </Row>

                <br />

                <StepTitle>
                    O endereço irá pertencer a:
                </StepTitle>

                <Row>
                    <ColSm12>
                        <StepSubTitle>
                            <div>
                                <b>Cliente</b>: {data?.endereco?.novo_morador?.nome}
                            </div>
                            <div>
                                <b>CPF</b>: {data?.endereco?.novo_morador?.cpf}
                            </div>
                        </StepSubTitle>
                    </ColSm12>
                </Row>
            </div>

            <Footer>

                <ButtonSecondary onClick={onBack}>
                    Etapa anterior
                </ButtonSecondary>

                <ButtonPrimary onClick={onRequestConfirm} >
                    Confirmar
                </ButtonPrimary>

            </Footer>
        </Content>
    )

}

export default Etapa2MigrarEndereco;