// API
import api from '~/services/api';

const GerarPdf = async (id = '', dados = {}) => {

    try {

        const { data: response_data } = await api.get(`/private/relatorios/${id}`, { params: dados }, {responseType: 'json'});


        return response_data;


    } catch (error) {
      
        const response = error?.response?.data || {};
                
        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const GerarXls = async (id = '', dados = {}) => {

    try {

        const { data: response_data } = await api.get(`/private/relatorios/xls/${id}`, { params: dados }, {responseType: 'json'});

        return response_data;
   
    } catch (error) {
      
        const response = error?.response?.data || {};
                
        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const RelatoriosRequests = {
    gerarPdf: GerarPdf,
    gerarXls: GerarXls,
};

export default RelatoriosRequests;