import React, { useCallback, useRef, useMemo, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Form } from '@unform/web';


// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";
import InputGroup, { InputGroupAddon, InputGroupButton } from "~/components/Bootstrap/InputGroup";
import InputSmall from "~/components/Inputs/Normal/Small";
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import Row from '~/components/Bootstrap/Row';

// STYLED COMPONENTS
import { Main, Content, Footer } from "../styles";

// TABLE
import Table from '~/components/TableR';

// BUTONS
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

// UTILS
import NotificacaoUtil from '~/utils/notificacao';
import Utils from "~/utils";

// SERVICES
import APIRequests from "~/services/requests_api";


const TabCreditoCarteira = ({ UUID }) => {

    /**
    * Dados de paginação para crédito da carteira
    */
    const [NavPageCountCarteira, setNavPageCountCarteira] = useState(0);
    const [NavCurrentPageCarteira, setNavCurrentPageCarteira] = useState(1);
    const [NavLimitPerPageCarteira, setNavLimitPerPageCarteira] = useState(10);
    const [NavTotalRowsCarteira, setNavTotalRowsCarteira] = useState(0);

    const [RegistrosCarteira, setRegistrosCarteira] = useState([]);

    const [SaldoCaixa, setSaldoCaixa] = useState(0);

    /**
     * Carrega os  registros de crédito carteira
     */
    const onRequestListaRegistrosCarteira = useCallback(async () => {

        const dados_credito_carteira = await APIRequests.credito_carteira.listar(UUID, NavCurrentPageCarteira, NavLimitPerPageCarteira);

        if (dados_credito_carteira.results) {
            setNavPageCountCarteira(dados_credito_carteira.metadata.page.total);
            setNavTotalRowsCarteira(dados_credito_carteira.metadata.total);

            /**
             * Carrega os dados adicionais do usuário
             */
            setRegistrosCarteira(dados_credito_carteira.results);
        } 
    }, [
        NavLimitPerPageCarteira,
        NavCurrentPageCarteira,
        UUID
    ]);

    /**
     * Buscando saldo
     */
     const onRequestSaldoCarteira = useCallback(async () => {

        const saldo = await APIRequests.credito_carteira.saldo(UUID);

        if (saldo) {
            /**
             * Carrega os dados adicionais do usuário
             */
            setSaldoCaixa(saldo);
        }
    }, [
        NavLimitPerPageCarteira,
        NavCurrentPageCarteira,
        UUID
    ]);


    /**
     * Quando navegar na tabela de carteira
     */
    const onTableNavChangeCarteira = useCallback((data) => {
        setNavLimitPerPageCarteira(data.limit);
        setNavCurrentPageCarteira(data.page + 1);
    }, [])


    /**
     * Dispara requisições para backend.
     */
    useEffect(() => {
        const timeout = setTimeout(() => {
            onRequestListaRegistrosCarteira();
            onRequestSaldoCarteira();
        }, 100);
        return () => clearTimeout(timeout);
    }, [
        UUID,
        NavLimitPerPageCarteira,
        NavCurrentPageCarteira,
    ]);

    /**
     * Colunas da tabela
     */
    const TableColumns = useMemo(() => [
        {
            accessor: 'id',
            width: 20,
            disableSortBy: true,
            Header: () => (
                <center>
                    #
                </center>
            ),

            Cell: ({ value }) => {
                return (
                    <center>
                        {value}
                    </center>
                )
            }

        },
        {
            Header: 'Tipo',
            accessor: 'tipo',
            disableSortBy: true,
            width: 30,

            Header: () => (
                <center>
                    Tipo
                </center>
            ),

            Cell: ({ value }) => {
                return (
                    <center>
                        {value}
                    </center>
                )
            }
        },
        {
            Header: 'Histórico',
            accessor: 'historico',
            disableSortBy: true,
        },
        {
            Header: 'Entrada',
            accessor: 'entrada',
            width: 30,
            disableSortBy: true,
            Header: () => (
                <center>
                    Entrada
                </center>
            ),
            Cell: ({ value }) => {
                return (
                    <center>
                        {Utils.FormataValor(value)}
                    </center>
                )
            }
        },
        {
            Header: 'Saída',
            accessor: 'saida',
            width: 30,
            disableSortBy: true,
            Header: () => (
                <center>
                    Saída
                </center>
            ),
            Cell: ({ value }) => {
                return (
                    <center>
                        {Utils.FormataValor(value)}
                    </center>
                )
            }
        },
        {
            Header: 'Data',
            accessor: 'criado_em',
            width: 60,
            disableSortBy: true,
            Header: () => (
                <center>
                    Data
                </center>
            ),
            Cell: ({ value }) => {
                return (
                    <center>
                        {Utils.FormataDataHora(value)}
                    </center>
                )
            }
        },
    ], []);

    const TableInitialSortBy = [{
        id: 'criado_em',
        desc: true
    }]


    return (
        <>
            <Row>
                <ColSm3>
                    <InputGroup small>
                        <InputGroupAddon>
                            SALDO
                        </InputGroupAddon>
                        <InputSmall
                            style={{ color: SaldoCaixa > 0 ? '#41924c' : '#b14545' }}
                            value={Utils.FormataTotal(SaldoCaixa)}
                            disabled
                        />
                    </InputGroup>
                </ColSm3>
            </Row>

            <Table
                nostriped

                initialSortBy={TableInitialSortBy}

                pageIndex={NavCurrentPageCarteira - 1}
                pageCount={NavPageCountCarteira}
                totalRows={NavTotalRowsCarteira}

                data={RegistrosCarteira}
                columns={TableColumns}

                onChangeNav={onTableNavChangeCarteira}
            />
        </>
    );

}



export default TabCreditoCarteira;