import styled from 'styled-components';

import THEME_CONSTS from '~/consts/theme';

import ButtonBase from '~/components/ButtonBase';

const InputGroup = styled.div`
    display: inline-flex;

    height: ${props => props.small ? '30px' : '32px'};

    span:first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    span:last-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    button:first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    button:last-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    input:not(:first-child):not(:last-child) {
        border-radius: 0px;
        border-left: 0px;
        border-right: 0px;
    }
    
    input:not(:first-child) {
        border-left: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    input:not(:last-child) {
        border-right: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

`;

export const InputGroupAddon = styled.span`
    z-index: 0 !important;

    display: inline-flex;
    align-items: center;

    height: 100%;

    padding-left: 10px;
    padding-right: 10px;

    font-size: 12px;
    font-weight: 400;
    border-radius: 3px;
    color: ${THEME_CONSTS.FONT_PRIMARY_COLOR};
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    
    white-space: nowrap;
`;

export const InputGroupButton = styled(ButtonBase)`
    display: inline-flex;
    align-items: center;

    height: calc(100% + 2px);

    padding-left: 10px;
    padding-right: 10px;

    font-size: 12px;
    font-weight: 400;
    border-radius: 3px;
    color: ${THEME_CONSTS.FONT_PRIMARY_COLOR};
    text-align: center;

    background-color: #fff;
    border: 1px solid #ccc;
    
    white-space: nowrap;
`;

export default InputGroup;