import React, { useRef, useMemo, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Form } from '@unform/web';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm5, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import InputGroup from '~/components/Bootstrap/InputGroup';
import Label from '~/components/Bootstrap/Label';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer, PhotoContainer, Photo } from "./styles";

// INPUTS
import InputFormNormal from '~/components/Inputs/Form/Normal';
import SelectFormNormal from '~/components/Selects/Form/Normal';

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

// UTILS
import NotificacaoUtil from '~/utils/notificacao';

// IMAGEM SEM FOTO
import ImageNoPhoto from '~/images/no-photo.png'

// SERVICES
import APIRequests from "~/services/requests_api";

const ModalCadastroEdicao = forwardRef(
    ({
        onConfirmCadEdit = () => { }
    }, ref) => {

        /**
         * Cria a referencia do formulário.
         */
        const FormREF = useRef(null);

        /**
         * Dados iniciais do form.
         */
        const [FormInitialData, setFormInitialData] = useState({});

        const [isVisible, setVisible] = useState(false);
        const [UUID, setUUID] = useState('');

        const onRequestConfirm = () => {
            FormREF?.current?.submitForm();
        }

        const onSubmit = async (data) => {

            try {

                const objeto_enviar = data;

                let retorno_api = {};
                if (UUID) {
                    retorno_api = await APIRequests.usuarios.atualizar(UUID, objeto_enviar);
                } else {
                    retorno_api = await APIRequests.usuarios.adicionar(objeto_enviar);
                }

                if (retorno_api?.id) {

                    NotificacaoUtil.success({
                        msg: UUID ? `Usuário atualizado com sucesso` : 'Usuário cadastrado com sucesso',
                    });

                    // fecha o modal
                    onRequestClose();

                    // dispara a função externa
                    onConfirmCadEdit();

                } else {
                    NotificacaoUtil.error({
                        msg: UUID ? `Erro ao atualizar o usuário` : `Erro ao cadastrar o usuário`,
                        timeout: 3500,
                    });
                }

            } catch (error) {
                NotificacaoUtil.error({
                    msg: error.message,
                    timeout: 3500,
                });
            }

        }

        const onRequestOpen = async (uuid = false) => {

            let visible = true;

            if (uuid) {

                const dados_usuario = await APIRequests.usuarios.obter(uuid);

                if (dados_usuario.uuid) {

                    /**
                     * Armazena o UUID do usuário.
                     */
                    setUUID(dados_usuario.uuid);

                    /**
                     * Carrega os dados adicionais do usuário
                     */
                    setFormInitialData(dados_usuario);

                } else {
                    visible = false;
                    NotificacaoUtil.error({
                        msg: 'Falha ao carregar os dados do usuario.'
                    });
                }

            }

            if (visible) {
                setVisible(visible);
            }

        }

        const onRequestClose = () => {
            setVisible(false);

            // reseta o estado
            setUUID('');

        }

        const onKeyDown = (e) => {

            // pega o código pressionado
            const code = e.which !== false ? e.which : e.keyCode;

            // stop events
            if (code == 13 || code == 27) {
                e.preventDefault();
            }

            if (code == 13) {
                onRequestConfirm();
            }

            if (code == 27) {
                onRequestClose();
            }

        }

        /**
         * Carrega os dados no form
         */
        useEffect(() => {
            if (FormREF?.current && FormInitialData?.uuid) {
                FormREF?.current.setData(FormInitialData);
            }
        }, [isVisible, FormREF, FormInitialData])

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <Modal
                isVisible={isVisible}
                setVisible={onRequestClose}
                closeButtonVisible={false}
                closeOnClickOutside={false}
                width={480}
                height={625}
            >
                <Main>
                    <ModalHeader>
                        {UUID ? 'Edição' : 'Cadastro'} de Usuario
                    </ModalHeader>

                    <Content>

                        <Form
                            ref={FormREF}
                            onSubmit={onSubmit}
                        >

                            <PhotoContainer>
                                <Photo src={FormInitialData.foto_red || ImageNoPhoto} />
                            </PhotoContainer>

                            <Row>
                                <ColSm12>
                                    <Label>CPF/CNPJ</Label>
                                    <InputFormNormal
                                        disabled
                                        name="cpf"
                                        mask='cpf_cnpj'
                                        onKeyDown={onKeyDown}
                                    />
                                </ColSm12>
                            </Row>

                            <Row>
                                <ColSm6>
                                    <Label>Nome</Label>
                                    <InputFormNormal
                                        name="nome"
                                        onKeyDown={onKeyDown}
                                    />
                                </ColSm6>
                                <ColSm6>
                                    <Label>Munícipe?</Label>
                                    <SelectFormNormal
                                        name="municipe"
                                        onKeyDown={onKeyDown}
                                    >
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </SelectFormNormal>
                                </ColSm6>
                            </Row>

                            <Row>
                                <ColSm6>
                                    <Label>Celular</Label>
                                    <InputFormNormal
                                        mask="phone"
                                        name="celular"
                                        onKeyDown={onKeyDown}
                                    />
                                </ColSm6>
                                <ColSm6>
                                    <Label>Email</Label>
                                    <InputFormNormal
                                        name='email'
                                        onKeyDown={onKeyDown}
                                    />
                                </ColSm6>
                            </Row>

                            <Footer>

                                <ButtonSecondary type="button" onClick={onRequestClose}>
                                    Fechar
                                </ButtonSecondary>

                                <ButtonPrimary type='submit'>
                                    OK
                                </ButtonPrimary>

                            </Footer>

                        </Form>

                    </Content>
                </Main>
            </Modal>
        )

    }
);

export default ModalCadastroEdicao;