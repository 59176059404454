// API
import api from '~/services/api';

const Listar = async (query = '', page = 1, limit = 1) => {

    try {

        const uquery = `query=${query}`
        const ulimit = `limit=${limit}`
        const upage = `page=${page}`

        const query_params = `?${uquery}&${upage}&${ulimit}`

        const { data: response_data } = await api.get(`/private/enderecos${query_params}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Obter = async (uuid = '') => {

    try {

        const { data: response_data } = await api.get(`/private/enderecos/${uuid}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const QuantidadePendentes = async () => {

    try {

        const { data: response_data } = await api.get(`/private/enderecos/pendentes`, { responseType: 'json' });

        return response_data?.enderecos_pendentes || 0;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}


const Atualizar = async (uuid = '', dados = {}) => {

    try {

        const { data: response_data } = await api.put(`/private/enderecos/${uuid}`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const MigrarEndereco = async (dados = {}) => {

    try {

        const { data: response_data } = await api.put(`/private/enderecos/${dados.endereco_uuid}/migracao_endereco`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Aprovar = async (
    uuid = '',
    dados = {
        aprovado: true,
        observacoes: ''
    }
) => {

    try {

        const objeto_enviar = {
            status: dados?.aprovado === true ? 'aprovado' : 'recusado',
            aprovado: dados?.aprovado || true,
            observacoes: dados?.observacoes || '',
        }

        const { data: response_data } = await api.put(`/private/enderecos/${uuid}/aprovar`, objeto_enviar, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}


const EnderecosRequests = {
    listar: Listar,
    qtd_pendentes: QuantidadePendentes,
    obter: Obter,
    atualizar: Atualizar,
    aprovar: Aprovar,
    migracao_de_endereco: MigrarEndereco
};

export default EnderecosRequests;