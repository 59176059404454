import React, { useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm4, ColSm8 } from '~/components/Bootstrap/Col';
import Label from '~/components/Bootstrap/Label';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// BUTONS
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

// UTILS
import moment from '~/utils/moment';
import Utils from '~/utils';

// SERVICES
import EspeciesPagamentoService from '~/services/especies-pag';
import TextareaNormal from '~/components/Textareas/Normal';

const ModalDetalhesLancamentoCaixa = forwardRef(
    ({}, ref) => {

        /**
         * Espécies de pagamento.
         */
        const EspeciesPag = EspeciesPagamentoService.especies;

        const TIPO_OP_ENTRADA = 'entrada';
        const TIPO_OP_SAIDA = 'saida';


        const [isVisible, setVisible] = useState(false);

        const [Data, setData] = useState({});


        const onRequestOpen = async (data) => {
            setData(data);
            setVisible(true);
        }

        const onRequestClose = () => {
            setVisible(false);

            // reseta o estado
            setData({});
        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <>
                <Modal
                    isVisible={isVisible}
                    setVisible={onRequestClose}
                    closeButtonVisible={false}
                    closeOnClickOutside={false}
                    width={720}
                    height={452}
                >
                    <Main>
                        <ModalHeader>
                            Lançamento
                        </ModalHeader>

                        <Content>

                            <Row>
                                <ColSm8>
                                    <Label>Histórico</Label>
                                    <InputNormal value={Data.historico} disabled readOnly />
                                </ColSm8>

                                <ColSm4>
                                    <Label>Data movimento</Label>
                                    <InputNormal
                                        value={moment(Data.data_movimento).format('DD/MM/YYYY HH:mm:ss')}
                                        disabled readOnly
                                    />
                                </ColSm4>

                            </Row>

                            <Row>

                                <ColSm4>
                                    <Label>Tipo da operação</Label>
                                    <SelectNormal
                                        value={Data.tipo}
                                        disabled readOnly
                                    >
                                        <option value={TIPO_OP_ENTRADA}>Entrada</option>
                                        <option value={TIPO_OP_SAIDA}>Saída</option>
                                    </SelectNormal>
                                </ColSm4>

                                <ColSm4>
                                    <Label>Valor</Label>
                                    <InputNormal value={Utils.FormataTotal(Data.entrada || Data.saida)} disabled readOnly/>
                                </ColSm4>

                                <ColSm4>
                                    <Label>Espécie</Label>
                                    <SelectNormal value={Data.especie} disabled readOnly>
                                        {EspeciesPag.map(especie => (
                                            <option key={especie} value={especie}>{especie}</option>
                                        ))}
                                    </SelectNormal>
                                </ColSm4>
                            </Row>

                            <Row>
                                <ColSm12>
                                    <Label>Observações</Label>
                                    <TextareaNormal
                                        rows={5}
                                        value={Data.observacoes}
                                        disabled
                                        readOnly
                                    />
                                </ColSm12>
                            </Row>

                            <Footer>

                                <ButtonSecondary onClick={onRequestClose}>
                                    Fechar
                                </ButtonSecondary>

                            </Footer>
                        </Content>
                    </Main>
                </Modal>

            </>
        )

    }
);

export default ModalDetalhesLancamentoCaixa;