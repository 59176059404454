import React, { useEffect, useRef, useState } from "react";
import { Form } from '@unform/web';

// SERVICES
import APIRequests from "~/services/requests_api";

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm5, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import Label from '~/components/Bootstrap/Label';

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle, BasePageButtonsContainer, BasePageTopLineSeparator } from "~/components/BasePage";

// INPUTS
import InputFormNormal from '~/components/Inputs/Form/Normal';
import SelectFormNormal from "~/components/Selects/Form/Normal";
import TextareaNormal from '~/components/Textareas/Normal';


// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from '~/components/Buttons/Small/Primary';
import NotificacaoUtil from "~/utils/notificacao";
import Utils from "~/utils/index";

export default function PageConfiguracoes() {

    /**
      * Cria a referencia do formulário.
      */
    const FormREF = useRef(null);

    /**
     * Valor min crédito
     */
    const [ValorMinCredito, setValorMinCredito] = useState();

    /**
    * Qtd litros no primerio cadastro
    */
    const [QtdLitrosPrimCadastro, setQtdLitrosPrimCadastro] = useState();

    /**
     * Qtd litros bonus semanal 
     */
    const [QtdLitrosBonusSemanal, setQtdLitrosBonusSemanal] = useState();

    /**
     * Termos de uso do app
     */
     const [TermoDeUsoApp, setTermoDeUsoApp] = useState('');

    


    /**
     * Dados iniciais do form.
     */
    const [FormInitialData, setFormInitialData] = useState({});

    const goLoadConfigs = async () => {

        const dados_configs = await APIRequests.configs.obter(1);

        if (dados_configs.id) {

            FormREF?.current?.setData(dados_configs);

            setTermoDeUsoApp(dados_configs?.termos_de_uso);

        } else {
            visible = false;
            NotificacaoUtil.error({
                msg: 'Falha ao carregar as configurações'
            });
        }

    }

    const onSubmit = async (data = {}) => {

        try {
            const dados_configs = await APIRequests.configs.atualizar(1, { ...data, termos_de_uso: TermoDeUsoApp } );

            if (dados_configs.id) {
        
                NotificacaoUtil.success({
                    msg: `Configurações atualizadas com sucesso`,
                });
    
            } else {
    
                NotificacaoUtil.error({
                    msg: 'Falha ao salvar as configurações',
                });
    
            }
        } catch (error) {
            NotificacaoUtil.error({
                msg: 'Falha ao salvar as configurações',
            });
        }
     

    }

    const onRequestSubmit = () => {
        FormREF?.current?.submitForm();
    }

    /**
     * Carrega a listagem de usuários quando carregar a página.
     */
    useEffect(() => {
        goLoadConfigs();
    }, []);

    return (
        <BasePageMain>
            <NavigationBar />

            <BasePageContainer>

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Configurações
                    </BasePageTitle>
                </BasePageTitleContainer>

                <BasePageTopLineSeparator />

                <BasePageButtonsContainer>
                    <ButtonPrimary onClick={onRequestSubmit}>
                        Salvar Configurações
                    </ButtonPrimary>
                </BasePageButtonsContainer>

                <BasePageBox>

                    <Form
                        initialData={FormInitialData}
                        ref={FormREF}
                        onSubmit={onSubmit}
                    >

                        <Row>
                            <ColSm4>
                                <Label>Tema App - BG Color Primary</Label>
                                <InputFormNormal
                                    name="theme_bg_color"
                                />
                            </ColSm4>
                            <ColSm4>
                                <Label>Tema App - BG Color Secondary</Label>
                                <InputFormNormal
                                    name="theme_bg_color_secondary"
                                />
                            </ColSm4>
                            <ColSm4>
                                <Label>Tema App - Font Color</Label>
                                <InputFormNormal
                                    name="theme_color"
                                />
                            </ColSm4>
                        </Row>

                        <Row>
                            <ColSm6>
                                <Label>Tema App - Statusbar BG Color</Label>
                                <InputFormNormal
                                    name="theme_bg_statusbar_color"
                                />
                            </ColSm6>
                            <ColSm6>
                                <Label>Tema App - Statusbar Text Color</Label>
                                <InputFormNormal
                                    name="theme_statusbar_text_color"
                                />
                            </ColSm6>
                        </Row>

                        <br />
                        <br />

                        <Row>
                            <ColSm4>
                                <Label>Gate2All - Ambiente</Label>
                                <SelectFormNormal name="g2all_ambiente">
                                    <option value="P">P</option>
                                    <option value="H">H</option>
                                </SelectFormNormal>
                            </ColSm4>
                            <ColSm4>
                                <Label>Gate2All - Usuário</Label>
                                <InputFormNormal
                                    name="g2all_usuario"
                                />
                            </ColSm4>
                            <ColSm4>
                                <Label>Gate2All - Token</Label>
                                <InputFormNormal
                                    name="g2all_token"
                                />
                            </ColSm4>
                        </Row>

                        <br />
                        <br />


                        <Row>

                            <ColSm4>
                                <Label>Valor mínimo crédito</Label>
                                <InputFormNormal
                                    name="valor_min_credito"
                                    value={ValorMinCredito}
                                    onChange={e => {
                                        const { value } = e.target;

                                        const valor = Utils.MascaraInputDecimal2Dig(value);

                                        if (valor !== -1) {
                                            setValorMinCredito(valor);
                                        }
                                    }}
                                />
                            </ColSm4>

                            <ColSm4>
                                <Label>Quantidade de litros fornecidos no primeiro cadastro</Label>
                                <InputFormNormal
                                    name="qtd_litros_prim_cadastro"
                                    value={QtdLitrosPrimCadastro}
                                    onChange={e => {
                                        const { value } = e.target;

                                        const valor = Utils.MascaraInputDecimal2Dig(value);

                                        if (valor !== -1) {
                                            setQtdLitrosPrimCadastro(valor);
                                        }
                                    }}
                                />
                            </ColSm4>
                            <ColSm4>
                                <Label>Quantidade de litros bônus semanal</Label>
                                <InputFormNormal
                                    name="qtd_litros_bonus"
                                    value={QtdLitrosBonusSemanal}
                                    onChange={e => {
                                        const { value } = e.target;

                                        const valor = Utils.MascaraInputDecimal2Dig(value);

                                        if (valor !== -1) {
                                            setQtdLitrosBonusSemanal(valor);
                                        }
                                    }}
                                />
                            </ColSm4>
                        </Row>

                        <br />

                        <Row>

                            <ColSm4>
                                <Label>Termos de uso visualizados no app</Label>
                                <TextareaNormal
                                    rows={3}
                                    // resize='none'
                                    autoComplete="none"

                                    value={TermoDeUsoApp}
                                    onChange={(e) => setTermoDeUsoApp(e.target.value)}
                                />
                            </ColSm4>

                        </Row>

                    </Form>

                </BasePageBox>
            </BasePageContainer>
        </BasePageMain>
    );
}
