import styled from 'styled-components';

export const Main = styled.div`
    font-family: 'Roboto Condensed';
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Content = styled.div`
    width: calc(100% - (20px * 2));
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const StepTitle = styled.span`
    display: inline-flex;
    flex-direction: column;
    font-size: 20px;
    color: #444;
    margin-bottom: 10px;
`;

export const StepSubTitle = styled.span`
    display: inline-flex;
    flex-direction: column;
    font-size: 18px;
    color: #666;
    margin-bottom: 10px;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 22px;

    button {
        margin-right: 10px;

        :last-child {
            margin-right: 0;
        }
    }
`;