import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import FirebaseService from "./services/firebase";

/**
 * Rotas default
 */
import MainRoutes from "./routes";

/**
 * Configura o firebase
 */
FirebaseService.Setup();

/**
 * Render default
 */
ReactDOM.render(<MainRoutes />, document.getElementById('root'));
