import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";

// SERVICES
import APIRequests from "~/services/requests_api";

// COMPONENTES GERAIS
import InputSmall from "~/components/Inputs/Normal/Small";
import SearchInput from "~/components/SearchInput";

import Row from "~/components/Bootstrap/Row";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle, BasePageButtonsContainer, BasePageTopLineSeparator } from "~/components/BasePage";

// BOOTSTRAP
import { ColSm2, ColSm3, ColSm7, ColSm9 } from "~/components/Bootstrap/Col";

// TABLE
import Table from '~/components/TableR';

// COMPONENTES GERAIS
import DateCalendarModalV2 from "~/components/DateCalendarModalV2";
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from '~/components/Buttons/Small/Primary';

// UTILS
import Utils from "~/utils";
import moment from "~/utils/moment";

export default function PageVendas() {

    const RefModalData = useRef(null);

    /**
     * Data atual com o moment.
     */
    const TodayDateMoment = moment();

    /**
     * Dados de navegação
     */
    const [NavSorted, setNavSorted] = useState([]);
    const [NavPageCount, setNavPageCount] = useState(0);
    const [NavCurrentPage, setNavCurrentPage] = useState(1);
    const [NavLimitPerPage, setNavLimitPerPage] = useState(10);
    const [NavTotalRows, setNavTotalRows] = useState(0);

    /**
     * Lista de Vendas
     */
    const [Vendas, setVendas] = useState([]);

    const [Pesquisa, setPesquisa] = useState('');
    const [Pesquisar, setPesquisar] = useState(false);

    const [Data, setData] = useState(TodayDateMoment.format('DD/MM/YYYY'));
    const [DataUS, setDataUS] = useState(TodayDateMoment.format('YYYY-MM-DD'));

    /**
     * Carrega os Vendas da
     */
    const goLoadVendas = useCallback(async () => {

        const vend = await APIRequests.vendas.listar({
            query: Pesquisa,
            date: DataUS,
            page: NavCurrentPage,
            limit: NavLimitPerPage,
            sorted: NavSorted
        });

        if (vend.results) {
            setVendas(vend.results);
            setNavPageCount(vend.metadata.page.total);
            setNavTotalRows(vend.metadata.total);
        }

        setPesquisar(false);

    }, [
        Pesquisa,
        DataUS,
        NavLimitPerPage,
        NavCurrentPage,
        NavSorted
    ]);

    /**
     * Abre o modal para editar.
     */
    const onRequestEditVenda = (uuid) => {
        // RefModalCad?.current?.open(uuid);
    }

    /**
     * Abre o modal de data.
     */
    const onRequestChangeDate = async () => {
        const date = await RefModalData.current.RequestDate({ current_date: DataUS });

        if (date.status == 'ok') {
            const nw_date = moment(date.date).format('DD/MM/YYYY');

            setNavCurrentPage(1);
            setPesquisar(true);
            setDataUS(date.date);
            setData(nw_date);
        }
    }

    /**
     * Quando navegar na tabela
     */
    const onTableNavChange = useCallback((data) => {
        setNavSorted(data.sortBy);
        setNavLimitPerPage(data.limit);
        setNavCurrentPage(data.page + 1);
    }, [])

    /**
     * Dispara requisições para backend.
     */
    useEffect(() => {
        let timeout = setTimeout(() => {
            goLoadVendas();
        }, 100);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        }
    }, [
        DataUS,
        Pesquisa,
        NavLimitPerPage,
        NavCurrentPage,
        NavSorted
    ]);

    /**
     * Colunas da tabela
     */
    const TableColumns = useMemo(() => [
        // {
        //     accessor: 'uuid',
        //     width: 40,
        //     disableSortBy: true,
        //     Header: () => (
        //         <center>
        //             #
        //         </center>
        //     ),
        //     Cell: ({ value }) => {
        //         return (
        //             <center>
        //                 <ButtonPrimary onClick={() => onRequestEditVenda(value)}>
        //                     <i className="fas fa-eye" />
        //                 </ButtonPrimary>
        //             </center>
        //         )
        //     }
        // },
        {
            Header: 'Data',
            accessor: 'criado_em',
            width: 150
        },
        {
            Header: 'Usuário',
            accessor: 'usuario.nome',
            Cell: ({ value, row }) => {
                const { original } = row;
                return (
                    <>
                        {original?.usuario?.nome} {original?.usuario?.sobrenome}
                    </>
                )
            }
        },
        {
            Header: 'Produto',
            accessor: 'produto_descricao',
            width: 115,
        },
        {
            Header: 'Valor Total',
            accessor: 'valor_total',
            width: 120,
            Cell: ({ value }) => {
                return Utils.FormataTotal(value);
            }
        },
        {
            Header: 'Status',
            accessor: 'status',
            width: 100,
        },

    ], []);

    const TableInitialSortBy = [{
        id: 'criado_em',
        desc: true
    }]

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Vendas
                    </BasePageTitle>
                </BasePageTitleContainer>

                <BasePageBox id="box">
                    <Row>
                        <ColSm2>
                            <InputSmall
                                value={Data}
                                readOnly
                                onClick={onRequestChangeDate}
                            />
                        </ColSm2>
                        <ColSm7 />
                        <ColSm3>
                            <SearchInput
                                inputType="small"

                                onSearch={(text) => {
                                    setNavCurrentPage(1)
                                    setPesquisar(true)
                                    setPesquisa(text)
                                }}
                            />
                        </ColSm3>
                    </Row>

                    <div style={{ marginTop: 10 }}>
                        <Table
                            nostriped

                            initialSortBy={TableInitialSortBy}

                            pageIndex={NavCurrentPage - 1}
                            pageCount={NavPageCount}
                            totalRows={NavTotalRows}

                            data={Vendas}
                            columns={TableColumns}

                            onChangeNav={onTableNavChange}
                        />
                    </div>
                </BasePageBox>
            </BasePageContainer>

            <DateCalendarModalV2
                ref={RefModalData}
            />

        </BasePageMain >
    );
}
