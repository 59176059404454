import React from 'react';

// TABLE
import { Table, Tbody, Tr, Td } from '~/components/Table';

// STYLED COMPONENTS
import { MsgLstNone } from "../styles";

const TabUsuariosUnidadeConsumidora = ({ EnderecosIguais }) => {

  return (
    <>
        
      {EnderecosIguais?.length > 0 ? (
            <Table >
                <Tbody>
                    {EnderecosIguais?.map((end) => {
                        return (
                            <Tr key={end.id}>
                                <Td>{end?.usuario?.nome}</Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table> 
            )
        :
            <MsgLstNone style={{ textAlign: 'center', marginTop: 15 }}>Não há usuários com a mesma unidade consumidora</MsgLstNone>
        }

    </>
  )
}

export default TabUsuariosUnidadeConsumidora;