import React, { useMemo, useEffect, useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import InputGroup from '~/components/Bootstrap/InputGroup';
import Label from '~/components/Bootstrap/Label';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";
import Spinner from "~/components/Spinner";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

// UTILS
import numeral from '~/utils/numeral';
import Utils from '~/utils';
import NotificacaoUtil from '~/utils/notificacao';

// SERVICES
import APIRequests from "~/services/requests_api";

const ModalConsultarStatus = forwardRef(
    ({
    }, ref) => {

        const [isVisible, setVisible] = useState(false);

        const [UUID, setUUID] = useState('');
        const [Descricao, setDescricao] = useState('');
        const [Endpoint, setEndpoint] = useState('');
        const [PortaSerial, setPortaSerial] = useState('');
        const [ControladoraID, setControladoraID] = useState('');
        const [DadosStatusBica, setDadosStatusBica] = useState({});
        const [NomeBica, setNomeBica] = useState('');

        const [isLoading, setIsLoading] = useState(false);


        


        const onRequestConfirm = async () => {

            try {

                if (!Descricao) {
                    throw new Error('Informe o descricao');
                }

                if (!Endpoint) {
                    throw new Error('Informe o endpoint');
                }

                if (!PortaSerial) {
                    throw new Error('Informe a porta serial');
                }

                if (!ControladoraID) {
                    throw new Error('Informe a porta serial');
                }

                const objeto_enviar = {
                    descricao: Descricao,
                    endpoint: Endpoint,
                    porta_serial: PortaSerial,
                    controladora_id: ControladoraID,
                }

                let retorno_api = {};
                if (UUID) {
                    retorno_api = await APIRequests.bicas_dagua.atualizar(UUID, objeto_enviar);
                } else {
                    retorno_api = await APIRequests.bicas_dagua.adicionar(objeto_enviar);
                }

                if (retorno_api?.id) {

                    NotificacaoUtil.success({
                        msg: UUID ? `Bica D'Agua atualizada com sucesso` : `Bica D'Agua cadastrada com sucesso`,
                    });

                    // fecha o modal
                    onRequestClose();

                    // dispara a função externa
                    onConfirmCadEdit();

                } else {
                    NotificacaoUtil.error({
                        msg: UUID ? `Erro ao atualizar a bica d'agua` : `Erro ao cadastrar a bica d'agua`,
                        timeout: 3500,
                    });
                }

            } catch (error) {
                NotificacaoUtil.error({
                    msg: error.message,
                    timeout: 3500,
                });
            }

        }

        const onRequestOpen = async (uuid = false, nome_bica) => {
            setUUID(uuid);
            setNomeBica(nome_bica);

            setVisible(true); 
        }

        const onRequestStatusBica = async () => {

            setIsLoading(true);

            const dados_status_bica = await APIRequests.bicas_dagua.status(UUID);

            if (dados_status_bica) {

                setDadosStatusBica(dados_status_bica?.data);

            } else {

                NotificacaoUtil.error({
                    msg: `Falha ao carregar o status da  bica d'água`
                });
            }

            setIsLoading(false);

        }

        const onRequestClose = () => {
            setVisible(false);

            // reseta o estado
            setUUID('');
            setDescricao('');
            setEndpoint('');
            setPortaSerial('');
            setControladoraID('');
            setDadosStatusBica('');
            setNomeBica('');
        }

        const onKeyDown = (e) => {

            // pega o código pressionado
            const code = e.which !== false ? e.which : e.keyCode;

            // stop events
            if (code == 13 || code == 27) {
                e.preventDefault();
            }

            if (code == 13) {
                onRequestConfirm();
            }

            if (code == 27) {
                onRequestClose();
            }

        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        useEffect(() => {
            onRequestStatusBica();
        },[isVisible]);

        return (
            <Modal
                isVisible={isVisible}
                setVisible={onRequestClose}
                closeButtonVisible={false}
                closeOnClickOutside={false}
                width={520}
                height={400}
            >
                <Main>
                    <ModalHeader>
                        Status - {NomeBica}
                    </ModalHeader>

                    <Content>

                        <Row>
                            <ColSm12>
                                <Label>UUID</Label>
                                <InputNormal
                                    value={UUID}
                                    disabled
                                    style={{ textAlign: 'center' }}
                                />
                            </ColSm12>
                        </Row>

                        <Row>
                            <ColSm4>
                                <Label>ID</Label>
                                <InputNormal
                                    value={DadosStatusBica.id}
                                    disabled
                                />
                            </ColSm4>

                            <ColSm8>
                                <Label>Status</Label>
                                {DadosStatusBica?.status ? (
                                     <InputNormal
                                     value={`${DadosStatusBica?.status} - ${Utils.CapitalizeFirstLetter(DadosStatusBica?.status_string).replace('_',' ')}`}
                                     disabled
                                 />
                                ) : (
                                    <InputNormal
                                    value=""
                                    disabled
                                />
                                )} 
                              
                            </ColSm8>
                        
                        </Row>

                        <Row>
                            <ColSm4>
                                <Label>Litros a sair</Label>
                                <InputNormal
                                    value={Utils.FormataValor(DadosStatusBica.litros_sair, false)}
                                    disabled
                                />
                            </ColSm4>

                            <ColSm4>
                                <Label>Litros sairam</Label>
                                <InputNormal
                                    value={Utils.FormataValor(DadosStatusBica.litros_sairam, false)}
                                    disabled
                                />
                            </ColSm4>

                            <ColSm4>
                                <Label>Preço compra</Label>
                                <InputNormal
                                    value={Utils.FormataValor(DadosStatusBica.preco_compra, true)}
                                    disabled
                                />
                            </ColSm4>

                        </Row>

                        <Footer>

                            <ButtonSecondary onClick={onRequestClose}>
                                Fechar
                            </ButtonSecondary>

                            <ButtonPrimary style={{minWidth: 150, display:'flex', justifyContent:'center'}} onClick={onRequestStatusBica}>
                                {isLoading ? (
                                    <Spinner size={13} color="#fff" /> 
                                ) : (
                                    <span>Atualizar status</span>
                                ) } 
                            </ButtonPrimary>

                        </Footer>

                    </Content>
                </Main>
            </Modal>
        )

    }
);

export default ModalConsultarStatus;