const EspeciesPagamento = [
    'Dinheiro',
    'Cartão de Crédito',
    'Cartão de Débito',
    'Cheque',
    'Depósito',
    'Pix',
];

const EspeciesPagamentoService = {
    especies: EspeciesPagamento,
}

export default EspeciesPagamentoService;