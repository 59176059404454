import "./styles.css";
import React, { useEffect, useMemo } from 'react';

import {
    useTable,
    useFlexLayout,
    useBlockLayout,
    useAbsoluteLayout,
    usePagination,
    useSortBy,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce
} from 'react-table'

// TABLE
import { Table, Thead, Tbody, Tr, Th, Td } from '~/components/Table';

const TableR = ({
    nostriped = false,

    columns = [],
    data = [],

    pageCount: ControlledPageCount = 1,
    pageIndex: ControlledPageIndex = 0,
    totalRows: numberOfTotalRows = 0,

    initialPageSize = 10,

    initialSortBy = [],

    // eventos
    onChangeNav = ({ page = 0, limit = 0 }) => { },
}) => {

    const columnData = useMemo(() => columns, [columns]);
    const rowData = useMemo(() => data, [data]);

    const use_table = useTable(
        {
            columns: columnData,
            data: rowData,
            autoResetPage: false,
            manualPagination: true,
            manualSortBy: true,
            initialState: {

                pageIndex: ControlledPageIndex,
                pageCount: 0,

                pageSize: initialPageSize,

                sortBy: initialSortBy

            },
            pageCount: ControlledPageCount,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useAsyncDebounce,
        usePagination,
        useFlexLayout
    );

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page


        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy },
    } = use_table;

    // evento de navegação
    useEffect(() => {
        onChangeNav({ page: pageIndex, limit: pageSize, sortBy: sortBy });
    }, [pageIndex, pageSize, sortBy]);

    /**
     * Monta o sistema de nave
     */
    const NavPageLimitBeforeDots = 2;
    const NavPageLimitAfterDots = 2;
    const NavPagesNumbers = [...new Array(ControlledPageCount)].map((n, i) => i);

    // Render the UI for your table
    return (

        <div>
            <Table
                {...getTableProps()}

                nostriped={nostriped}
            >
                <Thead>
                    {headerGroups.map(headerGroup => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <Th
                                    {...column.getHeaderProps(column.getSortByToggleProps({
                                        title: column.canSort ? 'Clique para ordenar' : '',
                                    }))}
                                >
                                    {column.render('Header')}
                                    {column.canSort && (
                                        <span className="sorting-control">
                                            {column.isSorted ? (
                                                <i
                                                    style={{ color: '#5a5a5a' }}
                                                    className={
                                                        column.isSortedDesc ? `fas fa-sort-amount-down` : `fas fa-sort-amount-down-alt`
                                                    }></i>
                                            ) : (
                                                <i className={`fas fa-sort-alt`} style={{ color: '#bbb' }}></i>
                                            )}
                                        </span>
                                    )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                                })}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table >

            <div className='table-pagination'>

                <div>
                    <select
                        className="page-size"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 25, 50, 100, 150, 200, 250, 300, 350].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>

                    <div className="infos">
                        {numberOfTotalRows} registro(s)
                    </div>
                </div>

                <div className='pagination'>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                    >
                        <i className="fad fa-angle-double-left"></i>
                    </button>
                    <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        <i className="fad fa-angle-left"></i>
                    </button>

                    <button
                        disabled={true}
                    >
                        {pageIndex + 1}
                    </button>

                    {/* {NavPageBeforeDots.map((num, index) => (
                        <button
                            key={num}
                            className={num == pageIndex ? 'active' : ''}
                            onClick={() => gotoPage(num)}
                        >
                            {num + 1}
                        </button>
                    ))}

                    {NavPageAfterDots.map((num, index) => (
                        <button
                            key={num}
                            className={num == pageIndex ? 'active' : ''}
                            onClick={() => gotoPage(num)}
                        >
                            {num + 1}
                        </button>
                    ))} */}
                    <button
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        <i className="fad fa-angle-right"></i>
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <i className="fad fa-angle-double-right"></i>
                    </button>
                </div>

            </div>

        </div >
    )
}

export default TableR;