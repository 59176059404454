import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import debounce from 'lodash/debounce';

// SERVICES
import EspeciesPagamentoService from '~/services/especies-pag';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import Label from '~/components/Bootstrap/Label';

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// COMPONENTS
import ReactSelect from '~/components/ReactSelect';

// STYLED COMPONENTS
import { StepTitle, StepSubTitle, Content, Footer } from "~/pages/Private/Caixa/components/ModalNovoCreditoUsuario/styles";

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary'

// UTILS
import Utils from '~/utils';
import NotificacaoUtil from '~/utils/notificacao';

const Etapa3NovoCreditoUsuario = ({
    data = {},
    onConfirm = async (data) => { },
    onBack = async () => { },
}) => {

    const onRequestConfirm = () => {
        onConfirm();
    }

    return (
        <Content style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <StepTitle>
                    Confirme os dados do usuário
                </StepTitle>

                <Row>
                    <ColSm12>
                        <StepSubTitle>
                            <div>
                                <b>Nome</b>: {data?.usuario?.nome}
                            </div>
                            <div>
                                <b>CPF</b>: {data?.usuario?.cpf}
                            </div>
                            <div>
                                <b>Celular</b>: {data?.usuario?.celular}
                            </div>
                        </StepSubTitle>
                    </ColSm12>
                </Row>

                <br />

                <StepTitle>
                    Confirmar dados de pagamento
                </StepTitle>

                <Row>
                    <ColSm12>
                        <StepSubTitle>
                            <div>
                                <b>Valor do crédito</b>: {Utils.FormataTotal(data?.pagamento?.valor || 0)}
                            </div>
                            <div>
                                <b>Espécie</b>: {data?.pagamento?.especie}
                            </div>
                        </StepSubTitle>
                    </ColSm12>
                </Row>
            </div>

            <Footer>

                <ButtonSecondary onClick={onBack} disabled={data?.sending === true}>
                    Etapa anterior
                </ButtonSecondary>

                <ButtonPrimary onClick={onRequestConfirm} disabled={data?.sending === true}>
                    Confirmar
                </ButtonPrimary>

            </Footer>
        </Content>
    )

}

export default Etapa3NovoCreditoUsuario;