import React, { useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm4 } from '~/components/Bootstrap/Col';

// COMPONENTS INTERNOS
import TabGeralEndereco from './components/TabGeralEndereco';
import TabHistoricoEndereco from './components/TabHistoricoEndereco';
import TabUsuariosUnidadeConsumidora from './components/TabUsuariosUnidadeConsumidora';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer, TabContainer } from "./styles";

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';
import ButtonDanger from '~/components/Buttons/Normal/Danger';
import ButtonSuccess from '~/components/Buttons/Normal/Success';
import SmallButtonPrimary from '~/components/Buttons/Small/Primary';

// UTILS
import NotificacaoUtil from '~/utils/notificacao';

// SERVICES
import APIRequests from "~/services/requests_api";

const ModalCadastroEdicao = forwardRef(
    ({
        onConfirmCadEdit = () => { }
    }, ref) => {

        /**
         * Tabs Disponíveis
         */
        const TAB_GERAL = 'geral';
        const TAB_HISTORICO = 'historico';
        const TAB_ENDERECOS_IGUAIS = 'enderecos_iguais';

        /**
         * Qual tab está visível
         */
        const [TabVisible, setTabVisible] = useState(TAB_GERAL);

        /**
         * Dados iniciais do form.
         */
        const [DadosEndereco, setDadosEndereco] = useState({});

        const [isVisible, setVisible] = useState(false);
        const [UUID, setUUID] = useState('');

        const [Observacao, setObservacao] = useState('');

        const [SaldoCarteira, setSaldoCarteira] = useState(0);

        const onSubmit = async (data) => {

            // try {

            //     const objeto_enviar = data;

            //     let retorno_api = {};
            //     if (UUID) {
            //         retorno_api = await APIRequests.enderecos.atualizar(UUID, objeto_enviar);
            //     } else {
            //         retorno_api = await APIRequests.enderecos.adicionar(objeto_enviar);
            //     }

            //     if (retorno_api?.id) {

            //         NotificacaoUtil.success({
            //             msg: UUID ? `Endereço atualizado com sucesso` : 'Endereço cadastrado com sucesso',
            //         });

            //         // fecha o modal
            //         onRequestClose();

            //         // dispara a função externa
            //         onConfirmCadEdit();

            //     } else {
            //         NotificacaoUtil.error({
            //             msg: UUID ? `Erro ao atualizar o endereço` : `Erro ao cadastrar o endereço`,
            //             timeout: 3500,
            //         });
            //     }

            // } catch (error) {
            //     NotificacaoUtil.error({
            //         msg: error.message,
            //         timeout: 3500,
            //     });
            // }

        }

        const onRequestUpdateObs = async () => {

            try {

                const retorno_api = await APIRequests.enderecos.atualizar(UUID, {
                    observacoes: Observacao
                });

                if (retorno_api?.id) {

                    NotificacaoUtil.success({
                        msg: UUID ? `Endereço atualizado com sucesso` : 'Endereço cadastrado com sucesso',
                    });

                    // fecha o modal
                    onRequestClose();

                    // dispara a função externa
                    onConfirmCadEdit();

                } else {
                    NotificacaoUtil.error({
                        msg: UUID ? `Erro ao atualizar o endereço` : `Erro ao cadastrar o endereço`,
                        timeout: 3500,
                    });
                }

            } catch (error) {
                NotificacaoUtil.error({
                    msg: error.message,
                    timeout: 3500,
                });
            }

        }

        const onRequestAprovar = async (aprovar = true) => {
            try {

                const aprovar_api = await APIRequests.enderecos.aprovar(UUID, {
                    aprovado: aprovar,
                    observacoes: Observacao
                });

                if (aprovar_api?.id) {

                    NotificacaoUtil.success({
                        msg: aprovar === true ? `Endereço aprovado com sucesso` : `Endereço recusado com sucesso`,
                    });

                    // fecha o modal
                    onRequestClose();

                    // dispara a função externa
                    onConfirmCadEdit();

                } else {
                    NotificacaoUtil.error({
                        msg: aprovar === true ? `Erro ao aprovar o endereço` : `Erro ao recusar o endereço`,
                        timeout: 3500,
                    });
                }

            } catch (error) {
                NotificacaoUtil.error({
                    msg: error.message,
                    timeout: 3500,
                });
            }
        }

        const onRequestOpen = async (uuid = false) => {

            let visible = true;

            if (uuid) {

                const dados_endereco = await APIRequests.enderecos.obter(uuid);

                if (dados_endereco.uuid) {

                    /**
                     * Armazena o UUID do endereço.
                     */
                    setUUID(dados_endereco.uuid);

                    /**
                     * Carrega os dados adicionais do endereço
                     */
                    setDadosEndereco(dados_endereco);

                    /**
                     * Observações
                     */
                    setObservacao(dados_endereco?.observacoes ?? '');

                    /**
                     * Carrega o saldo do usuário
                     */
                    if (dados_endereco?.saldo_usuario?.carteira) {
                        setSaldoCarteira(dados_endereco?.saldo_usuario?.carteira);
                    }

                } else {
                    visible = false;
                    NotificacaoUtil.error({
                        msg: 'Falha ao carregar os dados do endereço.'
                    });
                }

            }

            if (visible) {
                setVisible(visible);
            }

        }

        const onRequestClose = () => {
            setVisible(false);

            // reseta o estado
            setUUID('');
            setObservacao('');
            setSaldoCarteira(0);
            setTabVisible(TAB_GERAL)
        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        /**
         * Verifica se a tab está ativa'.
         */
        const onActiveTab = (tab) => {
            return TabVisible === tab;
        }

        /**
         * Detecta a cor do status.
         */
        const goDetectStatusColor = (status) => {
            const stats = {
                'aguardando': '#eded9c',
                'aprovado': '#9cedb0',
                'recusado': '#e58787',
            };

            return stats?.[status] || '';
        }

        /**
        * Detecta a cor do status.
        */
        const goDetectStatusColorBorder = (status) => {
            const stats = {
                'aguardando': '#caca67',
                'aprovado': '#62bb78',
                'recusado': '#cf6464',
            };

            return stats?.[status] || '';
        }


        return (
            <Modal
                id="modal_cadastro_edicao_endereco"
                isVisible={isVisible}
                setVisible={onRequestClose}
                closeButtonVisible={false}
                closeOnClickOutside={false}
                width={1024}
                height={705}

                style={{ borderLeft: `10px solid ${goDetectStatusColorBorder(DadosEndereco?.status)}` }}
            >
                <Main>

                    <ModalHeader style={{borderTopLeftRadius: 0}}>
                        Endereço
                    </ModalHeader>

                    <Content>

                        <Row style={{marginBottom: 20}}>
                            <ColSm4>
                                <SmallButtonPrimary style={{ minWidth: '100%' }} onClick={() => setTabVisible(TAB_GERAL)} isActive={onActiveTab(TAB_GERAL)}>
                                    Dados Gerais
                                </SmallButtonPrimary>
                            </ColSm4>
                            <ColSm4>
                                <SmallButtonPrimary style={{ minWidth: '100%' }} onClick={() => setTabVisible(TAB_ENDERECOS_IGUAIS)} isActive={onActiveTab(TAB_ENDERECOS_IGUAIS)}>
                                    Usuários da Unidade Consumidora
                                </SmallButtonPrimary>
                            </ColSm4>
                            <ColSm4>
                                <SmallButtonPrimary style={{ minWidth: '100%' }} onClick={() => setTabVisible(TAB_HISTORICO)} isActive={onActiveTab(TAB_HISTORICO)}>
                                    Histórico
                                </SmallButtonPrimary>
                            </ColSm4>
                        </Row>

                        <TabContainer>
                            {TabVisible === TAB_GERAL && (
                                <TabGeralEndereco DadosEndereco={DadosEndereco} Observacao={Observacao} setObservacao={setObservacao} SaldoCarteira={SaldoCarteira} setTabVisible={setTabVisible}/>
                            )}

                            {TabVisible === TAB_HISTORICO && (
                                <TabHistoricoEndereco UUID={UUID} />
                            )}

                            {TabVisible === TAB_ENDERECOS_IGUAIS && (
                                <TabUsuariosUnidadeConsumidora EnderecosIguais={DadosEndereco?.enderecos_iguais} />
                            )}    
                        </TabContainer>

                    </Content>

                    <Footer>

                        <ButtonSecondary type="button" onClick={onRequestClose}>
                            Fechar
                        </ButtonSecondary>

                        {TabVisible === TAB_GERAL && <div>

                            {(DadosEndereco?.status == 'aguardando' || DadosEndereco?.status == 'recusado') ? (
                                <ButtonSuccess onClick={() => onRequestAprovar(true)}>
                                    Aprovar Endereço
                                </ButtonSuccess>
                            ) : null}

                            {(DadosEndereco?.status == 'aguardando' || DadosEndereco?.status == 'aprovado') ? (
                                <ButtonDanger onClick={() => onRequestAprovar(false)}>
                                    Recusar Endereço
                                </ButtonDanger>
                            ) : null}

                            {DadosEndereco?.status != 'aguardando' ? (
                                <ButtonPrimary onClick={onRequestUpdateObs}>
                                    Atualizar Observação
                                </ButtonPrimary>
                            ) : null}

                        </div>}
                        
                    </Footer>

                </Main>
            </Modal>
        )

    }
);

export default ModalCadastroEdicao;