import React, { useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12 } from '~/components/Bootstrap/Col';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// BUTONS
import ButtonDanger from '~/components/Buttons/Normal/Danger';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

// UTILS
import moment from '~/utils/moment';
import NotificacaoUtil from '~/utils/notificacao';

// SERVICES
import APIRequests from "~/services/requests_api";

const ModalEstornarLancamento = forwardRef(
    ({
        onConfirm = () => { }
    }, ref) => {

        const [isVisible, setVisible] = useState(false);

        const [Data, setData] = useState({});
        const [Sending, setSending] = useState(false);

        const onRequestConfirm = async () => {

            if (!Sending) {
                try {

                    setSending(true);

                    const slashes = `\n\n-----\n\n`;
                    const obs = `${Data?.observacoes ?? ''}${Data?.observacoes ? slashes : ''}Data do movimento original: ${moment(Data?.data_movimento).format('DD/MM/YYYY HH:mm:ss')}`;

                    // remove a palavra estorno para não adicionar duplicado
                    const historico = Data?.historico?.replaceAll('ESTORNO: ', '');

                    const objeto_enviar = {
                        historico: `ESTORNO: ${historico}`,
                        observacoes: obs,
                        entrada: Data?.tipo === 'entrada' ? 0 : Data?.saida,
                        saida: Data?.tipo === 'saida' ? 0 : Data?.entrada,
                        tipo: Data?.tipo === 'entrada' ? 'saida' : 'entrada',
                        especie: Data?.especie,

                        // precisa pra remover o saldo do usuário também
                        usuario_uuid: Data?.usuario_uuid ?? null,

                        // informa que é um estorno
                        estorno: true
                    }

                    const retorno_api = await APIRequests.caixa.adicionar(objeto_enviar);

                    setSending(false);

                    if (retorno_api?.id) {

                        NotificacaoUtil.success({
                            msg: `Estorno de lançamento realizado com sucesso`,
                        });

                        // fecha o modal
                        onRequestClose();

                        // dispara a função externa
                        onConfirm();

                    } else {
                        NotificacaoUtil.error({
                            msg: `Erro ao estornar o lançamento`,
                            timeout: 3500,
                        });
                    }

                } catch (error) {
                    setSending(false);
                    NotificacaoUtil.error({
                        msg: error.message,
                        timeout: 3500,
                    });
                }
            }

        }

        const onRequestOpen = async (data) => {
            setData(data);
            setVisible(true);
        }

        const onRequestClose = () => {
            setVisible(false);
        }


        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <>
                <Modal
                    isVisible={isVisible}
                    setVisible={onRequestClose}
                    closeButtonVisible={false}
                    closeOnClickOutside={false}
                    width={400}
                    height={210}
                >
                    <Main>
                        <ModalHeader>
                            Atenção
                        </ModalHeader>

                        <Content>

                            <Row>
                                <ColSm12>
                                    <center style={{ marginTop: 10 }}>Tem certeza que deseja estornar este lançamento?</center>
                                </ColSm12>
                            </Row>

                            <Footer>

                                <ButtonSecondary onClick={onRequestClose} disabled={Sending}>
                                    Fechar
                                </ButtonSecondary>

                                <ButtonDanger onClick={onRequestConfirm} disabled={Sending}>
                                    Estornar
                                </ButtonDanger>

                            </Footer>

                        </Content>
                    </Main>
                </Modal>

            </>
        )

    }
);

export default ModalEstornarLancamento;