import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import Label from '~/components/Bootstrap/Label';

// ETAPAS
import Etapa0MigrarEndereco from './Etapas/Etapa0';
import Etapa1MigrarEndereco from './Etapas/Etapa1';
import Etapa2MigrarEndereco from './Etapas/Etapa2';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main } from "./styles";

import NotificacaoUtil from '~/utils/notificacao';

// SERVICES
import APIRequests from "~/services/requests_api";

const ModalMigrarEndereco = forwardRef(
    ({}, ref) => {

        /**
         * Modal visível?
         */
        const [isVisible, setVisible] = useState(false);

        /**
         * Dados do usuário
         */
        const [DadosEndereco, setDadosEndereco] = useState({});

        /**
         * Indicador da etapa
         */
        const [Etapa, setEtapa] = useState(0);

        /**
         * Retorna a altura do modal
         */
        const getModalHeight = (etapa) => {
            const heights = {
                '0': 600,
                '1': 600,
                '2': 420,
            };
            return heights[etapa];
        }

        const onConfirmEtapa0 = (endereco = {}) => {

            setDadosEndereco(endereco);
            // botão de limpar user, dispara essa função tbm
            if (endereco) {
                setEtapa(1);
            }
        }

        const onConfirmEtapa1 = (novo_morador = {}) => {

            setDadosEndereco({ ...DadosEndereco, novo_morador});
            // botão de limpar user, dispara essa função tbm
           
            if (novo_morador) {
                setEtapa(2);
            }
        }

        const onConfirmEtapa2 = async () => {
            try {



                if (!DadosEndereco?.uuid) {
                    throw new Error('Selecione um endereço para continuar');
                }

                if (!DadosEndereco?.novo_morador.uuid) {
                    throw new Error('Selecione um usuário para continuar');
                }

            
                const objeto_enviar = {
                    usuario_uuid: DadosEndereco?.novo_morador.uuid,
                    endereco_uuid: DadosEndereco?.uuid                    
                }

                const retorno_api = await APIRequests.enderecos.migracao_de_endereco(objeto_enviar);

                if (retorno_api?.id) {

                    NotificacaoUtil.success({
                        msg: `Migração de endereço realizada com sucesso`,
                    });

                    // fecha o modal
                    onRequestClose();

                } else {
                    NotificacaoUtil.error({
                        msg: `Erro ao migrar o endereço`,
                        timeout: 3500,
                    });
                }

            } catch (error) {
                NotificacaoUtil.error({
                    msg: error.message,
                    timeout: 3500,
                });
            }
        }

        const onRequestOpen = async () => {
            setVisible(true);
        }

        const onRequestClose = () => {
            setDadosEndereco({});
            setEtapa(0);

            setVisible(false);
        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <Modal
                isVisible={isVisible}
                setVisible={onRequestClose}
                closeButtonVisible={false}
                closeOnClickOutside={false}
                width={620}
                height={getModalHeight(Etapa)}
            >
                <Main>
                    <ModalHeader>
                        Migrar endereço
                    </ModalHeader>

                    {Etapa == 0 && <Etapa0MigrarEndereco
                        data={{ endereco: DadosEndereco }}
                        onConfirm={onConfirmEtapa0}
                        onBack={() => onRequestClose()}
                    />}

                    {Etapa == 1 && <Etapa1MigrarEndereco
                        data={{  endereco: DadosEndereco }}
                        onConfirm={onConfirmEtapa1}
                        onBack={() => setEtapa(0)}
                    />}

                    {Etapa == 2 && <Etapa2MigrarEndereco
                        data={{  endereco: DadosEndereco }}
                        onConfirm={onConfirmEtapa2}
                        onBack={() => setEtapa(1)}
                    />} 
                </Main>
            </Modal>
        )

    }
);

export default ModalMigrarEndereco;