import React, { useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';

// COMPONENTS INTERNOS
import TabCreditoCarteira from './components/TabCreditoCarteira';
import TabCreditoBonus from './components/TabCreditoBonus';
import TabTransacoesOnline from './components/TabTransacoesOnline';

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";
import { ColSm4 } from '~/components/Bootstrap/Col';

// BUTONS
import SmallButtonPrimary from '~/components/Buttons/Small/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

const ModalHistoricoCreditos = forwardRef(({

}, ref) => {

    /**
     * Tabs Disponíveis
     */
    const TAB_BONUS = 'bonus';
    const TAB_CARTEIRA = 'carteira';
    const TAB_TRANSACOES_ONLINE = 'transacoes_online';

    /**
     * Qual tab está visível
     */
    const [TabVisible, setTabVisible] = useState(TAB_CARTEIRA);


    const [isVisible, setVisible] = useState(false);
    const [UUID, setUUID] = useState('');

    /**
     * Abre modal
     */
    const onRequestOpen = async (uuid = false) => {

        if (uuid) {
            setUUID(uuid);
            setVisible(true);
        }

    };


    const onRequestClose = () => {

        setVisible(false);

        // reseta o estado
        setUUID('');
        // setRegistrosCarteira([]);
        // setRegistrosBonus([]);

    }

    /**
     * Passa a função de buscar para fora do input via ref.
     */
    useImperativeHandle(ref, () => (
        {
            open: onRequestOpen,
            close: onRequestClose
        }
    ));

    /**
     * Verifica se a tab está ativa.
     */
    const onActiveTab = (tab) => {
        return TabVisible === tab;
    }


    return (
        <Modal
            isVisible={isVisible}
            setVisible={onRequestClose}
            closeButtonVisible={false}
            closeOnClickOutside={false}
            width={980}
            height={670}
        >
            <Main>
                <ModalHeader>
                    Histórico Transações Carteira/Bônus
                </ModalHeader>
                <Content>

                    <Row>
                        <ColSm4>
                            <SmallButtonPrimary style={{ minWidth: '100%' }} onClick={() => setTabVisible(TAB_BONUS)} isActive={onActiveTab(TAB_BONUS)}>
                                Histórico Bônus
                            </SmallButtonPrimary>
                        </ColSm4>
                        <ColSm4>
                            <SmallButtonPrimary style={{ minWidth: '100%' }} onClick={() => setTabVisible(TAB_CARTEIRA)} isActive={onActiveTab(TAB_CARTEIRA)}>
                                Histórico Carteira
                            </SmallButtonPrimary>
                        </ColSm4>
                        <ColSm4>
                            <SmallButtonPrimary style={{ minWidth: '100%' }} onClick={() => setTabVisible(TAB_TRANSACOES_ONLINE)} isActive={onActiveTab(TAB_TRANSACOES_ONLINE)}>
                                Histórico Transações Online
                            </SmallButtonPrimary>
                        </ColSm4>
                    </Row>

                    {TabVisible === TAB_BONUS && (
                        <TabCreditoBonus UUID={UUID} />
                    )}

                    {TabVisible === TAB_CARTEIRA && (
                        <TabCreditoCarteira UUID={UUID} />
                    )}

                    {TabVisible === TAB_TRANSACOES_ONLINE && (
                        <TabTransacoesOnline UUID={UUID} />
                    )}

                </Content>
                <Footer>

                    <ButtonSecondary style={{ marginLeft: 20, marginBottom: 20 }} type="button" onClick={onRequestClose}>
                        Fechar
                    </ButtonSecondary>

                </Footer>
            </Main>
        </Modal>
    )

});

export default ModalHistoricoCreditos;