import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select'
import debounce from 'lodash/debounce';

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle } from "~/components/BasePage";
import ReactAsyncSelect from '~/components/ReactSelect/async';

import THEME_CONSTS from '~/consts/theme';

import Row from "~/components/Bootstrap/Row";
import NotificacaoUtil from "~/utils/notificacao.js";

// BOOTSTRAP
import { ColSm2, ColSm3, ColSm4, ColSm5, ColSm6, ColSm7, ColSm9, ColSm12 } from "~/components/Bootstrap/Col";

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import InputSmall from "~/components/Inputs/Normal/Small";
import DateCalendarModalV2 from "~/components/DateCalendarModalV2";
import ButtonPrimary from '~/components/Buttons/Small/Primary';



// COMPONENTES INTERNOS 
import { LabelInput, Footer, ButtonEveryPeriod } from "../components/styles";

// SERVICES
import APIRequests from "~/services/requests_api";


// UTILS
import Utils from "~/utils";
import moment from "~/utils/moment";

export default function RelatorioEnderecosAprovados() {

    /**
     * Data atual com o moment.
     */
    const TodayDateMoment = moment();

    const RefModalData = useRef(null);

    const navigate = useNavigate();
    const [DataInicio, setDataInicio] = useState(TodayDateMoment.format('DD/MM/YYYY'));
    const [DataFim, setDataFim] = useState(TodayDateMoment.format('DD/MM/YYYY'));

    const [DateUSInitil, setDateUSInitil] = useState(TodayDateMoment.format('YYYY-MM-DD'));

    const [DataDisabled, setDataDisabled] = useState(false);
    const [ColorButtonSelectPeriod, setColorButtonSelectPeriod] = useState('');


    const [Clientes, setClientes] = useState('');
    const [OrdenarPor, setOrdenarPor] = useState('');
    const [Ordem, setOrdem] = useState('');

    /**
     * Array de usuários localizados.
     */
    const [SelectedUser, setSelectedUser] = useState('');

    /**
    * Função que é disparada depois de pesquisar
    */
    const goLoadOptions = React.useCallback(
        debounce((input_value, callback) => {

            /**
             * Pega os dados da api.
             * !Não da para usar await, precisa usar o then por causa do debounce
             */
            APIRequests.usuarios.listar({
                query: input_value,
                page: 1,
                limit: 30,
                filter_cpf_only: 0,
            }).then(({ results }) => {
                /**
                 * Gera a array com os novos dados.
                 */
                const options = results.map((reg) => ({
                    label: `${reg.nome} - ${reg.cpf}`,
                    value: reg.uuid, ...reg
                }));

                /**
                 * Executa o callback pra mostrar os dados
                 */
                callback(options);
            });

        }, 700),
        []
    );



    const optionsOrdenarPor = [
        { value: 'user.nome', label: 'Cliente' },
        { value: 'user.cpf', label: 'cpf/cnpj' },
        { value: 'usuarios_enderecos.logradouro', label: 'Logradouro' },
        { value: 'usuarios_enderecos.numero', label: 'Número' },
        { value: 'usuarios_enderecos.bairro', label: 'Bairro' },
        { value: 'usuarios_enderecos.municipio', label: 'Municipio' },
        { value: 'usuarios_enderecos.criado_em', label: 'Cadastrdo em' },
        { value: 'usuarios_enderecos.data_aprovacao', label: 'Aprovado em' }

    ]

    const optionsOrdem = [
        { value: 'ASC', label: 'Crescente' },
        { value: 'DESC', label: 'Decrescente' },

    ]

    /**
     * Abre o modal de data.
     */
    const onSelectDataInicio = async () => {
        const date = await RefModalData.current.RequestDate({ current_date: DateUSInitil });

        if (date.status == 'ok') {
            const nw_date = moment(date.date).format('DD/MM/YYYY');

            setDataInicio(nw_date);
        }
    }

    /**
     * Abre o modal de data.
     */
    const onSelectDataFim = async () => {
        const date = await RefModalData.current.RequestDate({ current_date: DateUSInitil });


        if (date.status == 'ok') {
            const nw_date = moment(date.date).format('DD/MM/YYYY');

            setDataFim(nw_date);
        }
    }



    /**
     * Envia dados para gerar pdf
     */

    const onGeneratePDF = async () => {

        try {

            /**
             * Verifica se existe data
             */
            const start_date = DataInicio ? moment(DataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00:00') : '';
            const end_date = DataFim ? moment(DataFim, 'DD/MM/YYYY').format('YYYY-MM-DD 23:59:59') : '';

            const objFiltros = {
                start_date: start_date,
                end_date: end_date,
                order_by: OrdenarPor,
                order_direction: Ordem,
                clientes: SelectedUser?.uuid || '',
            }

            const retorno_api = await APIRequests.relatorios.gerar_pdf('relatorio_enderecos_aprovados', objFiltros);

            window.open(retorno_api?.url);

        } catch (error) {
            NotificacaoUtil.error({
                msg: error.message
            });
        }

    }

    /**
     * Marca todo o periodo
     */
    const onSelectEveryPeriod = () => {

        if (DataDisabled) {
            setDataDisabled(false);
            setColorButtonSelectPeriod('');

            setDataInicio(TodayDateMoment.format('DD/MM/YYYY'));
            setDataFim(TodayDateMoment.format('DD/MM/YYYY'));
        } else {
            setDataDisabled(true);
            setColorButtonSelectPeriod(THEME_CONSTS.BUTTON_PRIMARY_BG_COLOR);

            setDataInicio('');
            setDataFim('');

        }
    }

    return (
        <BasePageMain id="main">
            <BasePageContainer id="container" style={{ backgroundColor:'#fff'}}>

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Relatório | Endereços aprovados
                    </BasePageTitle>
                </BasePageTitleContainer>



                <BasePageBox id="box" disabledFlex  style={{ border:'none', paddingLeft:'0px', paddingRight:'0px' }}>
                    <Row>
                        <LabelInput>Periodo</LabelInput>
                        <ColSm5>
                            <InputSmall
                                value={DataInicio}
                                readOnly
                                onClick={onSelectDataInicio}
                                disabled={DataDisabled}
                            />
                        </ColSm5>
                        <ColSm5>
                            <InputSmall
                                value={DataFim}
                                readOnly
                                onClick={onSelectDataFim}
                                disabled={DataDisabled}
                            />
                        </ColSm5>

                        <ColSm2>
                            <ButtonEveryPeriod backgroundColor={ColorButtonSelectPeriod} onClick={onSelectEveryPeriod}>
                                TODO O PERÍODO
                            </ButtonEveryPeriod>
                        </ColSm2>
                    </Row>

                    <Row>
                        <LabelInput>Clientes</LabelInput>
                        <ColSm12>
                            <ReactAsyncSelect
                                autoFocus
                                loadOptions={goLoadOptions}

                                value={SelectedUser}

                                {...(SelectedUser?.uuid ? {

                                    value: {
                                        value: SelectedUser?.value || '',
                                        label: SelectedUser?.label || '',
                                    }

                                } : {})}

                                placeholder="Digite um nome ou deixe em branco para trazer todos"

                                onChange={value => {
                                    setSelectedUser(value)
                                }}
                            />
                        </ColSm12>

                    </Row>

                    <Row>
                        <LabelInput>Ordenar por</LabelInput>
                        <ColSm12>
                            <Select placeholder={null}  options={optionsOrdenarPor} onChange={(options) => setOrdenarPor(options.value)} setValue={OrdenarPor} />
                        </ColSm12>

                    </Row>


                    <Row>
                        <LabelInput>Ordem</LabelInput>
                        <ColSm12>
                            <Select placeholder={null} options={optionsOrdem} onChange={(options) => setOrdem(options.value)} setValue={Ordem} />
                        </ColSm12>

                    </Row>



                </BasePageBox>

                <Footer>
                    <ButtonPrimary onClick={onGeneratePDF}>
                        GERAR PDF
                    </ButtonPrimary>
                </Footer>

            </BasePageContainer>

            <DateCalendarModalV2
                ref={RefModalData}
            />

        </BasePageMain>
    );
}
