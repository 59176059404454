import React, { useState, useEffect, useRef, useMemo } from "react";

// SERVICES
import APIRequests from "~/services/requests_api";

// COMPONENTES GERAIS
import SearchInput from "~/components/SearchInput";

import Row from "~/components/Bootstrap/Row";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle, BasePageButtonsContainer, BasePageTopLineSeparator } from "~/components/BasePage";

// TABLE
import { Table, Thead, Tbody, Tr, Th, Td } from '~/components/Table';

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from '~/components/Buttons/Small/Primary';

// COMPONENTES INTERNOS
import ModalCadastroEdicao from "./components/ModalCadEdit";
import ModalConsultarStatus from "./components/ModalStatusBica";

// SERVICES 
import AuthService from '~/services/auth';

// UTILS
import Utils from "~/utils";
import { ColSm3, ColSm9 } from "~/components/Bootstrap/Col";

export default function PageCadastroBicasDAgua() {

    const RefModalCad = useRef(null);
    const RefModalStatusBica = useRef(null);

    /**
     * Lista de BicasDAgua
     */
    const [BicasDAgua, setBicasDAgua] = useState([]);

    const [Pesquisa, setPesquisa] = useState('');
    const [Pesquisar, setPesquisar] = useState(false);

    // pega os dados do administrador logado
    const administrador = AuthService.getUserData();

    /**
     * Carrega os BicasDAgua da
     */
    const goLoadBicasDAgua = async () => {
        const BicasDAgua = await APIRequests.bicas_dagua.listar(Pesquisa);

        if (BicasDAgua.results) {
            setBicasDAgua(BicasDAgua.results);
        }
    }

    /**
     * Abre o modal para inserir.
     */
    const onRequestAddAdministrador = () => {
        RefModalCad?.current?.open();
    }

    /**
     * Abre o modal para editar.
     */
    const onRequestEditAdministrador = (uuid) => {
        RefModalCad?.current?.open(uuid);
    }

    /**
     * Abre o modal para consultar status
     */
    const onRequestStatusBica = (uuid, nome_bica) => {
        RefModalStatusBica?.current?.open(uuid, nome_bica);
    }


    /**
     * Ao confirmar o cadastro ou edição do Administrador.
     */
    const onConfirmCadEditAdministrador = () => {
        goLoadBicasDAgua();
    }

    /**
     * Carrega os BicasDAgua a cada pesquisa
     */
    useEffect(() => {
        if (Pesquisar === true) {
            goLoadBicasDAgua();
        }
    }, [Pesquisa, Pesquisar]);

    /**
     * Carrega a listagem de usuários quando carregar a página.
     */
    useEffect(() => {
        goLoadBicasDAgua();
    }, []);

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Cadastro de Bicas D'Agua
                    </BasePageTitle>
                </BasePageTitleContainer>

                <BasePageTopLineSeparator />

                <BasePageButtonsContainer>
                    <ButtonPrimary onClick={onRequestAddAdministrador}>
                        Adicionar
                    </ButtonPrimary>
                </BasePageButtonsContainer>

                <BasePageBox id="box">
                    <Row>
                        <ColSm9 />
                        <ColSm3>
                            <SearchInput
                                inputType="small"
                                onChange={(e) => setPesquisar(true)}
                                onSearch={(text) => setPesquisa(text)}
                            />
                        </ColSm3>
                    </Row>

                    <div style={{ marginTop: 10 }}>
                        <Table nostriped>
                            <Thead>
                                <Tr>
                                    <Th width={70}>
                                        <center>#</center>
                                    </Th>
                                    <Th>Descrição</Th>
                                    <Th width={220}>Endpoint</Th>
                                    <Th width={180}>Porta Serial</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {BicasDAgua.map((prod) => {
                                    return (
                                        <Tr key={prod.uuid}>
                                            <Td width={70}>
                                                <center>
                                                    <ButtonPrimary disabled={ !administrador.master } onClick={() => onRequestEditAdministrador(prod.uuid)}>
                                                        <i className="fas fa-pencil" />
                                                    </ButtonPrimary>

                                                    <ButtonPrimary onClick={() => onRequestStatusBica(prod.uuid, prod.descricao)}>
                                                        <i className="fas fa-plus-circle" />
                                                    </ButtonPrimary>
                                                </center>
                                            </Td>
                                            <Td>{prod.descricao}</Td>
                                            <Td width={220}>{prod.endpoint}</Td>
                                            <Td width={180}>{prod.porta_serial}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </div>
                </BasePageBox>
            </BasePageContainer>

            <ModalCadastroEdicao
                ref={RefModalCad}
                onConfirmCadEdit={onConfirmCadEditAdministrador}
            />

            <ModalConsultarStatus
                ref={RefModalStatusBica}
            />

        </BasePageMain >
    );
}
