import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select'

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle } from "~/components/BasePage";

import THEME_CONSTS from '~/consts/theme';

import Row from "~/components/Bootstrap/Row";
import NotificacaoUtil from "~/utils/notificacao.js";

// BOOTSTRAP
import { ColSm2, ColSm5, ColSm12 } from "~/components/Bootstrap/Col";

// COMPONENTES GERAIS
import InputSmall from "~/components/Inputs/Normal/Small";
import DateCalendarModalV2 from "~/components/DateCalendarModalV2";
import ButtonPrimary from '~/components/Buttons/Small/Primary';

// COMPONENTES INTERNOS 
import { LabelInput, Footer, ButtonEveryPeriod } from "../components/styles";

// SERVICES
import APIRequests from "~/services/requests_api";

// UTILS
import moment from "~/utils/moment";

export default function FechamentoCaixa() {

    /**
     * Data atual com o moment.
     */
    const TodayDateMoment = moment();

    const RefModalData = useRef(null);

    const navigate = useNavigate();
    const [DataInicio, setDataInicio] = useState(TodayDateMoment.format('DD/MM/YYYY'));
    const [DataFim, setDataFim] = useState(TodayDateMoment.format('DD/MM/YYYY'));

    const [DateUSInitil, setDateUSInitil] = useState(TodayDateMoment.format('YYYY-MM-DD'));

    const [DataDisabled, setDataDisabled] = useState(false);
    const [ColorButtonSelectPeriod, setColorButtonSelectPeriod] = useState('');

    const [Operador, setOperador] = useState('');

    const [ListaOperador, setListaOperador] = useState([]);

    const [OrdenarPor, setOrdenarPor] = useState('');
    const [Ordem, setOrdem] = useState('asc');

    const optionsOrdenarPor = [
        { value: 'administradores.nome', label: 'Operador' },
        { value: 'livro_caixa.data_movimento', label: 'Data' },
        { value: 'livro_caixa.historico', label: 'Histórico' },
        { value: 'livro_caixa.entrada', label: 'Entrada' },
        { value: 'livro_caixa.saida', label: 'Saída' },
        { value: 'livro_caixa.especie', label: 'Espécie' }
    ]

    const optionsOrdem = [
        { value: 'asc', label: 'Crescente' },
        { value: 'desc', label: 'Decrescente' },
    ]

    /**
     * Carrega os Administradores da
     */
    const goLoadAdministradores = async () => {
        const Administradores = await APIRequests.administradores.listar();

        let lista = [{ value: '', label: 'TODOS' }];

        if (Administradores.results) {

            Administradores.results.forEach(element => {
                lista.push({ value: element.uuid, label: element.nome });
            });

            setListaOperador(lista);

        }
    }


    /**
     * Abre o modal de data.
     */
    const onSelectDataInicio = async () => {
        const date = await RefModalData.current.RequestDate({ current_date: DateUSInitil });

        if (date.status == 'ok') {
            const nw_date = moment(date.date).format('DD/MM/YYYY');

            setDataInicio(nw_date);
        }
    }

    /**
     * Abre o modal de data.
     */
    const onSelectDataFim = async () => {
        const date = await RefModalData.current.RequestDate({ current_date: DateUSInitil });


        if (date.status == 'ok') {
            const nw_date = moment(date.date).format('DD/MM/YYYY');

            setDataFim(nw_date);
        }
    }

    /**
     * Envia dados para gerar relatórios
     */
    const onGenerateRelatorio = async (tipo = 'pdf') => {
        
        const start_date = DataInicio ? moment(DataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00:00') : '';
        const end_date = DataFim ? moment(DataFim, 'DD/MM/YYYY').format('YYYY-MM-DD 23:59:59') : '';

        const objFiltros = {
            start_date: start_date,
            end_date: end_date,
            operador: Operador,
            order_by: OrdenarPor,
            order_direction: Ordem
        }

        try {
            
            const routerName = 'gerar_' + tipo;
            const retorno_api = await APIRequests.relatorios[routerName]('fechamento_livro_caixa', objFiltros);

            window.open(retorno_api?.url);

        } catch (error) {
            NotificacaoUtil.error({
                msg: error.message
            });
        }
    }

    /**
     * Marca todo o periodo
     */
    const onSelectEveryPeriod = () => {

        if (DataDisabled) {
            setDataDisabled(false);
            setColorButtonSelectPeriod('');

            setDataInicio(TodayDateMoment.format('DD/MM/YYYY'));
            setDataFim(TodayDateMoment.format('DD/MM/YYYY'));
        } else {
            setDataDisabled(true);
            setColorButtonSelectPeriod(THEME_CONSTS.BUTTON_PRIMARY_BG_COLOR);

            setDataInicio('');
            setDataFim('');

        }
    }

    useEffect(() => {

        const CarregaListaOperadores = async () => {
            await goLoadAdministradores();
        }

        CarregaListaOperadores();

    }, []);


    return (
        <BasePageMain id="main">
            <BasePageContainer id="container" style={{ backgroundColor:'#fff'}}>

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Relatório | Fechamento de Caixa
                    </BasePageTitle>
                </BasePageTitleContainer>

                <BasePageBox id="box" disabledFlex  style={{ border:'none', paddingLeft:'0px', paddingRight:'0px' }}>
                    <Row>
                        <LabelInput>Periodo</LabelInput>
                        <ColSm5>
                            <InputSmall
                                value={DataInicio}
                                readOnly
                                onClick={onSelectDataInicio}
                                disabled={DataDisabled}
                            />
                        </ColSm5>
                        <ColSm5>
                            <InputSmall
                                value={DataFim}
                                readOnly
                                onClick={onSelectDataFim}
                                disabled={DataDisabled}
                            />
                        </ColSm5>

                        <ColSm2>
                            <ButtonEveryPeriod backgroundColor={ColorButtonSelectPeriod} onClick={onSelectEveryPeriod}>
                                TODO O PERÍODO
                            </ButtonEveryPeriod>
                        </ColSm2>
                    </Row>

                    <Row>
                        <LabelInput>Operador</LabelInput>
                        <ColSm12>
                            <Select placeholder={null} options={ListaOperador} onChange={(options) => setOperador(options.value)} setValue={Operador} />
                        </ColSm12>

                    </Row>

                    <Row>
                        <LabelInput>Ordenar por</LabelInput>
                        <ColSm12>
                            <Select  placeholder={null} options={optionsOrdenarPor} onChange={(options) => setOrdenarPor(options.value)} setValue={OrdenarPor} />
                        </ColSm12>
                    </Row>

                    <Row>
                        <LabelInput>Ordem</LabelInput>
                        <ColSm12>
                            <Select  placeholder={null} options={optionsOrdem} onChange={(options) => setOrdem(options.value)} setValue={Ordem} />
                        </ColSm12>
                    </Row>

                </BasePageBox>

                <Footer>
                    <ButtonPrimary onClick={() => onGenerateRelatorio()}>
                        GERAR PDF
                    </ButtonPrimary>

                    <ButtonPrimary onClick={() => onGenerateRelatorio('xls')}>
                        GERAR XLS
                    </ButtonPrimary>
                </Footer>

            </BasePageContainer>

            <DateCalendarModalV2
                ref={RefModalData}
            />

        </BasePageMain>
    );
}
