import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import debounce from 'lodash/debounce';

// SERVICES
import EspeciesPagamentoService from '~/services/especies-pag';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import Label from '~/components/Bootstrap/Label';

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// COMPONENTS
import ReactSelect from '~/components/ReactSelect';

// STYLED COMPONENTS
import { StepTitle, Content, Footer } from "~/pages/Private/Caixa/components/ModalNovoCreditoUsuario/styles";

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary'

// UTILS
import Utils from '~/utils';
import NotificacaoUtil from '~/utils/notificacao';

const Etapa2NovoCreditoUsuario = ({
    data = {},
    onConfirm = async (data) => { },
    onBack = async () => { },
}) => {

    const RefInputValor = useRef(null);

    /**
     * Espécies de pagamento.
     */
    const EspeciesPag = EspeciesPagamentoService.especies;

    const [Valor, setValor] = useState(data?.pagamento?.valor || '');
    const [Especie, setEspecie] = useState(data?.pagamento?.especie || EspeciesPag[0]);

    const onRequestConfirm = () => {
        try {

            const NValor = parseFloat(Valor?.toString()?.replace(/[\.]/g, "")?.replace(',', '.') || 0);

            if (NValor == 0) {
                RefInputValor?.current?.focus();
                throw new Error('Informe o valor do crédito a ser adicionado');
            }

            onConfirm({ valor: NValor, especie: Especie });

        } catch (error) {
            NotificacaoUtil.error({
                msg: error.message,
                timeout: 3500,
            });
        }

    }

    /**
     * Efetua o foco no próximo input.
     */
    const ProximaEspecie = () => {
        // localiza o index da especie selecionada
        const index = EspeciesPag.findIndex((el) => (el == Especie));

        // se tiver no final, começa no primeiro de novo
        if (index == (EspeciesPag.length - 1)) {
            var nw_index = 0;
        } else {
            var nw_index = index + 1;
        }

        // atualiza a especie selecionada
        setEspecie(EspeciesPag[nw_index]);
    }

    /**
     * Efetua o foco no input anterior.
     */
    const EspecieAnterior = () => {
        // localiza o index da especie selecionada
        const index = EspeciesPag.findIndex((el) => (el == Especie));

        // se tiver no começo começa no último de novo
        if (index == 0) {
            var nw_index = EspeciesPag.length - 1;
        } else {
            var nw_index = index - 1;
        }

        // atualiza a especie selecionada
        setEspecie(EspeciesPag[nw_index]);
    }

    const onKeyDown = (e) => {

        // pega o código pressionado
        const code = e.which !== false ? e.which : e.keyCode;

        // keys
        const key_up = 38;
        const key_down = 40;
        const key_enter = 13;
        const key_esc = 27;

        // stop events
        if (code == key_enter || code == key_esc || code == key_up || code == key_down) {
            e.preventDefault();
        }

        if (code == key_up) {
            EspecieAnterior();
        }

        if (code == key_down) {
            ProximaEspecie();
        }

        if (code == key_enter) {
            onRequestConfirm();
        }

        if (code == key_esc) {
            onRequestClose();
        }

    }

    return (
        <Content style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <StepTitle>
                    Informar o valor do crédito
                </StepTitle>

                <Row>

                    <ColSm12>
                        <InputNormal
                            autoFocus
                            ref={RefInputValor}
                            value={Valor}
                            onKeyDown={onKeyDown}
                            style={{ fontSize: 18 }}
                            onChange={e => {
                                const { value } = e.target;

                                const valor = Utils.MascaraInputValor2Dig(value);

                                if (valor !== -1) {
                                    setValor(valor);
                                }
                            }}
                        />
                    </ColSm12>

                </Row>
                <br />

                <StepTitle>
                    Informar a espécie de pagamento
                </StepTitle>

                <Row>
                    <ColSm12>

                        <ReactSelect
                            options={EspeciesPag.map(especie => ({ label: especie, value: especie }))}
                            value={{
                                value: Especie,
                                label: Especie
                            }}
                            defaultValue={{
                                value: EspeciesPag[0],
                                label: EspeciesPag[0]
                            }}
                            onChange={data => setEspecie(data.value)}
                        />

                        {/* <SelectNormal value={Especie} onChange={e => setEspecie(e.target.value)} onKeyDown={onKeyDown} style={{ fontSize: 18 }}>
                            {EspeciesPag.map(especie => (
                                <option key={especie} value={especie}>{especie}</option>
                            ))}
                        </SelectNormal> */}
                        <Label style={{ marginTop: 5, color: '#666' }}>
                            Use as teclas para baixo e para cima para navegar entre as espécies
                        </Label>
                    </ColSm12>
                </Row>
            </div>

            <Footer>

                <ButtonSecondary onClick={onBack}>
                    Etapa anterior
                </ButtonSecondary>

                <ButtonPrimary onClick={onRequestConfirm} disabled={!Valor}>
                    Próxima etapa
                </ButtonPrimary>

            </Footer>
        </Content>
    )

}

export default Etapa2NovoCreditoUsuario;