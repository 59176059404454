import React, {useCallback, useMemo, useEffect, useState} from 'react';

// BOOTSTRAP
import Label from '~/components/Bootstrap/Label';

// TABLE
import { Table, Tbody, Tr, Td } from '~/components/Table';

// UTILS
import Utils from "~/utils";

// SERVICES
import APIRequests from "~/services/requests_api";


const TabHistoricoEndereco = ({ UUID }) => {

    const [Historico, setHistorico] = useState([]);

    /**
     * Carrega os  registros de histórico do endereço
     */
    const onRequestListaHistoricoEndereco = useCallback(async () => {

        const dados_historico = await APIRequests.enderecos_logs.listar(UUID);

        if (dados_historico.results) {
            /**
             * Carrega os dados adicionais do histórico do endereço
             */
            setHistorico(dados_historico.results);
        }

    }, [ UUID ]);
    

    /**
     * Dispara requisições para backend.
     */
    useEffect(() => {
        let timeout = setTimeout(() => {
            onRequestListaHistoricoEndereco();
        }, 100);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        }
    }, [
        UUID,
    ]);

    /**
     * Verifica o tipo de operação.
     */
    const onOperacao = (reg) => {
        if(reg?.operacao === 'APROVAR/RECUSAR'){
            return reg?.descricao.includes('aprovado') ? 'APROVAR' : 'RECUSAR';
        }

        return reg?.operacao;
    };

    return (
        <>
            {Historico?.length > 0 && 
                <Table>
                    <Tbody>
                        {Historico?.map((reg) => {
                            return (
                                <Tr key={reg.id}>
                                    <Td style={{whiteSpace: 'unset'}}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Label style={{fontWeight: 'bold'}}>{onOperacao(reg)}</Label>
                                            <Label> {Utils.FormataDataHora(reg?.criado_em)}</Label>
                                        </div>
                                        <Label style={{marginBottom: 8}}>
                                            {reg?.descricao}
                                        </Label>
                                        <Label style={{fontWeight: 'bold', fontSize: 12, margin: 0}}>{`${reg?.usuario?.nome || reg?.administrador?.nome || 'Não encontrado'}  (${reg?.usuario_uuid ? 'Usuário' : 'Administrador'})`}</Label>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            }    
        </>
    )
}

export default TabHistoricoEndereco;