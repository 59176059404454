import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm4, ColSm8 } from '~/components/Bootstrap/Col';
import Label from '~/components/Bootstrap/Label';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";
import DateCalendarModalV2 from '~/components/DateCalendarModalV2';

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

// UTILS
import moment from '~/utils/moment';
import Utils from '~/utils';
import NotificacaoUtil from '~/utils/notificacao';

// SERVICES
import APIRequests from "~/services/requests_api";
import EspeciesPagamentoService from '~/services/especies-pag';
import TextareaNormal from '~/components/Textareas/Normal';

const ModalNovoLancamentoCaixa = forwardRef(
    ({
        onConfirm = () => { }
    }, ref) => {

        const RefModalData = useRef(null);
        const RefInputHistorico = useRef(null);
        const RefInputValor = useRef(null);

        /**
         * Espécies de pagamento.
         */
        const EspeciesPag = EspeciesPagamentoService.especies;

        const TIPO_OP_ENTRADA = 'entrada';
        const TIPO_OP_SAIDA = 'saida';

        /**
         * Data atual com o moment.
         */
        const TodayDateMoment = moment();

        const [isVisible, setVisible] = useState(false);
        const [VendaFicha, setVendaFicha] = useState(false);


        const [Historico, setHistorico] = useState('');
        const [DataMovimento, setDataMovimento] = useState(TodayDateMoment.format('YYYY-MM-DD'));
        const [HoraMovimento, setHoraMovimento] = useState(TodayDateMoment.format('HH:mm'));

        const [Valor, setValor] = useState('');
        const [Especie, setEspecie] = useState(EspeciesPag[0]);
        const [TipoOperacao, setTipoOperacao] = useState(TIPO_OP_ENTRADA);
        const [Observacoes, setObservacoes] = useState('');
        const [Sending, setSending] = useState(false);

        const onRequestConfirm = async () => {

            if (!Sending) {
                try {

                    setSending(true);

                    const NValor = parseFloat(Valor?.toString()?.replace(/[\.]/g, "")?.replace(',', '.') || 0);

                    if (!Historico) {
                        RefInputHistorico?.current?.focus();
                        throw new Error('Preencha o histórico da movimentação');
                    }

                    if (NValor == 0) {
                        RefInputValor?.current?.focus();
                        throw new Error('Preencha o valor da movimentação');
                    }

                    const objeto_enviar = {
                        historico: Historico,
                        observacoes: Observacoes,
                        entrada: TipoOperacao == TIPO_OP_ENTRADA ? NValor : 0,
                        saida: TipoOperacao == TIPO_OP_SAIDA ? NValor : 0,
                        tipo: TipoOperacao,
                        especie: Especie
                    }

                    const retorno_api = await APIRequests.caixa.adicionar(objeto_enviar);

                    setSending(false);

                    if (retorno_api?.id) {

                        NotificacaoUtil.success({
                            msg: `Movimentação adicionada com sucesso`,
                        });

                        // fecha o modal
                        onRequestClose();

                        // dispara a função externa
                        onConfirm();

                    } else {
                        NotificacaoUtil.error({
                            msg: `Erro ao adicionar a movimentação`,
                            timeout: 3500,
                        });
                    }

                } catch (error) {
                    setSending(false);
                    NotificacaoUtil.error({
                        msg: error.message,
                        timeout: 3500,
                    });
                }
            }

        }

        const onRequestOpen = async (venda_fichas = false) => {
            setVisible(true);
            setVendaFicha(venda_fichas);
            if (venda_fichas) {
                setHistorico('VENDA FICHAS');
            }
        }

        const onRequestClose = () => {
            setVisible(false);
            setVendaFicha(false);

            // reseta o estado
            setHistorico('');
            setValor('');
            setObservacoes('');
            setTipoOperacao(TIPO_OP_ENTRADA);
            setEspecie(EspeciesPag[0]);
        }

        const onRequestChangeDate = async () => {
            const date = await RefModalData.current.RequestDate({ current_date: DataMovimento });

            if (date.status == 'ok') {
                setDataMovimento(date.date);
            }
        }

        const onKeyDown = (e) => {

            // pega o código pressionado
            const code = e.which !== false ? e.which : e.keyCode;

            // keys
            const key_f1 = 112;
            const key_f2 = 113;
            const key_enter = 13;
            const key_esc = 27;

            // stop events
            if (code == key_enter || code == key_esc || code == key_f1 || code == key_f2) {
                e.preventDefault();
            }

            if (code == key_enter) {
                onRequestConfirm();
            }

            if (code == key_esc) {
                onRequestClose();
            }

            if (code == key_f1 && VendaFicha == false) {
                setTipoOperacao(TIPO_OP_ENTRADA);
            }

            if (code == key_f2 && VendaFicha == false) {
                setTipoOperacao(TIPO_OP_SAIDA);
            }

        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <>
                <Modal
                    isVisible={isVisible}
                    setVisible={onRequestClose}
                    closeButtonVisible={false}
                    closeOnClickOutside={false}
                    width={720}
                    height={480}
                >
                    <Main>
                        <ModalHeader>
                            Adicionar movimentação de caixa
                        </ModalHeader>

                        <Content>

                            <Row>
                                <ColSm12>
                                    <Label>Histórico</Label>
                                    <InputNormal
                                        autoFocus={VendaFicha == false}
                                        ref={RefInputHistorico}
                                        value={Historico}
                                        onKeyDown={onKeyDown}
                                        onChange={e => setHistorico(e.target.value?.toUpperCase())}
                                        disabled={VendaFicha}
                                    />
                                </ColSm12>
                            </Row>

                            <Row>

                                <ColSm4>
                                    <Label>Tipo da operação</Label>
                                    <SelectNormal
                                        disabled={VendaFicha}
                                        tabIndex={-1}
                                        value={TipoOperacao}
                                        onChange={e => setTipoOperacao(e.target.value)}
                                        onKeyDown={onKeyDown}
                                    >
                                        <option value={TIPO_OP_ENTRADA}>Entrada</option>
                                        <option value={TIPO_OP_SAIDA}>Saída</option>
                                    </SelectNormal>

                                    <div style={{ fontSize: 14, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                        <Label>
                                            F1 - Entrada
                                        </Label>
                                        <Label>
                                            F2 - Saída
                                        </Label>
                                    </div>
                                </ColSm4>

                                <ColSm4>
                                    <Label>Valor</Label>
                                    <InputNormal
                                        autoFocus={VendaFicha}
                                        ref={RefInputValor}
                                        value={Valor}
                                        onKeyDown={onKeyDown}
                                        onChange={e => {
                                            const { value } = e.target;

                                            const valor = Utils.MascaraInputValor2Dig(value);

                                            if (valor !== -1) {
                                                setValor(valor);
                                            }
                                        }}
                                    />
                                </ColSm4>

                                <ColSm4>
                                    <Label>Espécie</Label>
                                    <SelectNormal value={Especie} onChange={e => setEspecie(e.target.value)} onKeyDown={onKeyDown}>
                                        {EspeciesPag.map(especie => (
                                            <option key={especie} value={especie}>{especie}</option>
                                        ))}
                                    </SelectNormal>
                                </ColSm4>
                            </Row>

                            <Row>
                                <ColSm12>
                                    <Label>Observações</Label>
                                    <TextareaNormal
                                        rows={5}
                                        value={Observacoes}
                                        onChange={e => setObservacoes(e.target.value)}
                                        onKeyDown={onKeyDown}
                                    />
                                </ColSm12>
                            </Row>

                            <Footer>

                                <ButtonSecondary onClick={onRequestClose} disabled={Sending}>
                                    Fechar
                                </ButtonSecondary>

                                <ButtonPrimary onClick={onRequestConfirm} disabled={Sending}>
                                    OK
                                </ButtonPrimary>

                            </Footer>
                        </Content>
                    </Main>
                </Modal>

                <DateCalendarModalV2
                    ref={RefModalData}

                    animateOpacity={false}
                    animateTranlate={false}
                />
            </>
        )

    }
);

export default ModalNovoLancamentoCaixa;