// API
import api from '~/services/api';

const Obter = async (id = '') => {

    try {

        const { data: response_data } = await api.get(`/public/configs/${id}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Atualizar = async (id = '', dados = {}) => {

    try {

        const { data: response_data } = await api.put(`/public/configs/${id}`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const ConfigsRequests = {
    obter: Obter,
    atualizar: Atualizar,
};

export default ConfigsRequests;