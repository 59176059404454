import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

// SERVICES
import APIRequests from "~/services/requests_api";
import AuthService from "~/services/auth";
// COMPONENTES GERAIS
import SearchInput from "~/components/SearchInput";

import Row from "~/components/Bootstrap/Row";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle, BasePageButtonsContainer, BasePageTopLineSeparator } from "~/components/BasePage";

// TABLE
import { Thead, Tbody, Tr, Th, Td } from '~/components/Table';

// TABLE
import Table from '~/components/TableR';

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from '~/components/Buttons/Small/Primary';

// COMPONENTES INTERNOS
import ModalCadastroEdicao from "./components/ModalCadEdit";
import ModalMigrarEndereco from "./components/ModalMigrarEndereco";

// UTILS
import Utils from "~/utils";
import { ColSm3, ColSm9 } from "~/components/Bootstrap/Col";

export default function PageUsuariosEnderecos() {

    const RefModalCad = useRef(null);
    const RefModalMigrarEnd = useRef(null);
    /**
     * Lista de Usuarios
     */
    const [Enderecos, setEnderecos] = useState([]);

    const [Pesquisa, setPesquisa] = useState('');
    const [Pesquisar, setPesquisar] = useState(false);

    /**
     * Dados de navegação
     */
    const [NavPageCount, setNavPageCount] = useState(0);
    const [NavCurrentPage, setNavCurrentPage] = useState(1);
    const [NavLimitPerPage, setNavLimitPerPage] = useState(25);
    const [NavTotalRows, setNavTotalRows] = useState(0);

    /**
     * Carrega os Usuarios da
     */
    const goLoadEnderecos = useCallback(async () => {
        const Enderecos = await APIRequests.enderecos.listar(Pesquisa, NavCurrentPage, NavLimitPerPage);

        if (Enderecos.results) {
            setEnderecos(Enderecos.results);
            setNavPageCount(Enderecos.metadata.page.total);
            setNavTotalRows(Enderecos.metadata.total);

        }
    }, [
        Pesquisa,
        NavCurrentPage,
        NavLimitPerPage
    ]);

    /**
     * Abre o modal para inserir.
     */
    const onRequestAddEndereco = () => {
        RefModalCad?.current?.open();
    }

    /**
     * Abre o modal para editar.
     */
    const onRequestEditEndereco = (uuid) => {
        RefModalCad?.current?.open(uuid);
    }

    /**
     * Ao confirmar o cadastro ou edição do Endereco.
     */
    const onConfirmCadEditEndereco = () => {
        goLoadEnderecos();
    }

    /**
     * Abre modal de migração de endereço
     */

     const onRequestOpenModalMigrarEnd = async () => {
        await RefModalMigrarEnd?.current?.open();
    }

    /**
     * Quando navegar na tabela
     */
    const onTableNavChange = useCallback((data) => {
        setNavLimitPerPage(data.limit);
        setNavCurrentPage(data.page + 1);
    }, [])

    /**
     * Dispara requisições para backend.
     */
    useEffect(() => {
        let timeout = setTimeout(() => {
            goLoadEnderecos();
        }, 100);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        }
    }, [
        Pesquisa,
        NavLimitPerPage,
        NavCurrentPage
    ]);

    /**
     * Colunas da tabela
     */
    const TableColumns = useMemo(() => [
        {
            accessor: 'uuid',
            width: 40,
            disableSortBy: true,
            Header: () => (
                <center>
                    #
                </center>
            ),
            Cell: ({ value }) => {
                return (
                    <center>
                        {AuthService.checkPermission('enderecos', 'editar') ? (
                            <ButtonPrimary onClick={() => onRequestEditEndereco(value)}>
                                <i className="fas fa-eye" />
                            </ButtonPrimary>
                        ) : (
                            <ButtonPrimary disabled="disabled" onClick={() => onRequestEditEndereco(value)}>
                                <i className="fas fa-eye" />
                            </ButtonPrimary>
                        )}

                    </center>
                )
            }
        },
        {
            Header: 'Nome Usuário',
            accessor: 'usuario.nome',
            disableSortBy: true,
        },
        {
            Header: 'Unid. Consumid.',
            accessor: 'unidade_consumidora',
            disableSortBy: true,
            width: 140
        },
        {
            Header: 'Data cadastro',
            accessor: 'criado_em',
            disableSortBy: true,
            width: 140,
            Cell: ({ value }) => Utils.FormataDataHora(value)

        },
        {
            Header: 'Data atualização',
            accessor: 'atualizado_em',
            disableSortBy: true,
            width: 140,
            Cell: ({ value }) => Utils.FormataDataHora(value)
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: true,
            width: 140
        },
    ], []);


    const TableInitialSortBy = [{
        id: 'nome',
        desc: false
    }]

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Endereços
                    </BasePageTitle>
                </BasePageTitleContainer>

                <BasePageTopLineSeparator />

                <BasePageButtonsContainer>
                    {AuthService.checkPermission('enderecos', 'migrar_endereco') && (
                        <ButtonPrimary onClick={() => { onRequestOpenModalMigrarEnd() }}>
                            Migrar endereço
                        </ButtonPrimary>
                    )}


                </BasePageButtonsContainer>

                <BasePageBox id="box">
                    <Row>
                        <ColSm9 />
                        <ColSm3>
                            <SearchInput
                                inputType="small"
                                onChange={(e) => setPesquisar(true)}
                                onSearch={(text) => setPesquisa(text)}
                            />
                        </ColSm3>
                    </Row>
                    <div>
                        <div style={{ marginTop: 10 }}>
                            <Table
                                nostriped

                                initialPageSize={NavLimitPerPage}
                                initialSortBy={TableInitialSortBy}

                                pageIndex={NavCurrentPage - 1}
                                pageCount={NavPageCount}
                                totalRows={NavTotalRows}

                                data={Enderecos}
                                columns={TableColumns}

                                onChangeNav={onTableNavChange}
                            />
                        </div>
                    </div>
                </BasePageBox>
            </BasePageContainer>

            <ModalCadastroEdicao
                ref={RefModalCad}
                onConfirmCadEdit={onConfirmCadEditEndereco}
            />

            <ModalMigrarEndereco
                ref={RefModalMigrarEnd}
            />

        </BasePageMain >
    );
}
