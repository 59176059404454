// API
import api from '~/services/api';

const Listar = async (usuario_uuid, page = 1, limit = 100) => {

    try {
        const uusuario_uuid = `usuario=${usuario_uuid}`
        const upage        = `page=${page}`
        const ulimit        = `limit=${limit}`

        const query_params = `?${uusuario_uuid}&${upage}&${ulimit}`

        const { data: response_data } = await api.get(`/private/credito_hidrocaldas${query_params}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Saldo = async (usuario_uuid) => {

    try {

        const { data: response_data } = await api.get(`/private/credito_hidrocaldas/saldo?usuario_uuid=${usuario_uuid}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}




const CreditoBonusRequests = {
    listar: Listar,
    saldo: Saldo,
};

export default CreditoBonusRequests;